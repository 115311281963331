export const googlePopUp = function(type = 'layer', googleOAuth2Url = ''){
  // const win = type != 'layer' ? window : window.parent.window;
  const winWidth = 500;
  const winLeft = ($(window).width() - winWidth)/2;
  const winTop = 100;
  const windowFeatures = `width=${winWidth},height=600,left=${winLeft},top=${winTop},menubar=no`;
  if (type == 'layer') {
    window.parent.window.postMessage({
      googleOAuth2Url
    },'*');
    return;
  }else{
    $.cookie('landingpage', 'layer', { path: '/', secure: true });
    $.cookie('loginPage', '1', { path: '/', secure: true });
    const opener = window.open(googleOAuth2Url,'_blank',windowFeatures);
  }
}