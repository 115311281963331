import { regExp } from 'dm-utils-ts';

import { history } from '../detail-history';
const isEmail = regExp.isEmail;
window.common = Object.assign(window.common || {}, {
  email_url: null,
  searchRequest: null,
  fav_url: "",
  unsubscribe_url: "",
  report_error_html: null,
  report_error_form_url: null,
  get_hot_url: null,
  navUrl: null,
  contact_us_url: null,
  hot_url: null,
  category_beauty_url: null,
  category_computer_url: null,
  home_en_url: null,
  home_cn_url: null,
  is_fav: true,
  ishttps: false,
  englishLink: "",
  creditcardLink: "",
  storesLink: "",
  isStoresDisplay: false, // 是否显示商家相关信息, 默认不显示
  wechat_url: "/wechat",
  jubao_dialog: null,
});

// 后端截图广告，执行滚动操作
common.screenshot = {
  adP: '',
  adId: '',
  pageNum: '',
  srollToAd: function (adPosition) {
    document.body.style['scroll-behavior'] = 'auto';
    document.documentElement.style['scroll-behavior'] = 'auto';
    if (adPosition) {
      function scroll () {
        const $ad = $(`[data-ad-slot_pos=${adPosition}]`);
        if ($ad.length) {
          const top = $ad.offset().top - 48 - 8;
          $(window).scrollTop(top);
        }
        setTimeout(scroll, 100);
      }
      setTimeout(scroll, 2000);
    }
  },
  getAd: function (cb) {
    const url = '/www/deal/get-single-ad';
    const adId = this.adId;
    const adP = this.adP;
    const params = {
      adId: adId,
      adP: adP
    };
    $.ajax({
      type: 'POST',
      url: url,
      data: params,
      dataType: 'json',
      success: function (res) {
        if (res.status === 1) {
          const html = res.data.html;
          const $ad = $(html);
          if (cb) {
            cb($ad);
          }
        }
      }
    });
  },
  init: function () {
    const _this = this;
    window.onload = function() {
      const adId = _this.adId;
      const adPosition = _this.adP;
      if (Number(_this.pageNum) === 10 && adId) {
        _this.getAd(function ($ad) {
          $('#dealsList .mlist_box').prepend($ad);
          _this.srollToAd(adPosition);
        });
      } else if (Number(_this.pageNum) === 1 && adId) {
        _this.getAd(function ($ad) {
          const $replaceSlotAd = $('[data-ad-slot_pos=' + adPosition + ']');
          $replaceSlotAd.replaceWith($ad);
          _this.srollToAd(adPosition);
        });
      } else {
        _this.srollToAd(adPosition);
      }
    }
  }
}

//笔记攻略轮播
common.xScroll = {
  /**
   * 横向滚动，每页数量不为整数。
   * @param root 根节点
   * @param pageSize 每一页完整展示几个项目
   */
  scrollItem(root, pageSize) {
    const $root = $(root);
    if ($root.length === 0) {
      return;
    }
    const leftBtn = $root.find(".btns .left");
    const rightBtn = $root.find(".btns .right");
    const $items = $root.find(".scroolItems");
    const unitwidth = $items
      .children()
      .first()
      .outerWidth(true);
    let length = $items.children(".scroolItem").length;
    let pageNum = Math.ceil(length / pageSize) - 1;
    // tags
    const $tags = $root.find(".tags");
    let tagsWidth = 0;
    $tags.find("p.tag").each((index, tag) => {
      tagsWidth += $(tag).outerWidth(true);
    });
    // const tagScroll = (tagsWidth - $root.width()) / (pageNum - 1);
    const showPage = function(page) {
      if ($items.is(":animated")) {
        return;
      }
      const currentLeft = page * pageSize;
      const left = -1 * unitwidth * currentLeft + "px";
      $items.animate({ left }, "fast");
      // tag滚动
      if (tagsWidth !== 0 && tagsWidth - $root.width() > 0) {
        // const tagLeft = -1 * tagScroll * page + 'px';
        $tags.animate({ left: left }, "fast");
        // $tags.animate({ left: tagLeft }, 'fast');
      }
    };

    let currentPage = 0;
    $(leftBtn).on("click", function() {
      if (currentPage === 0) {
        currentPage = pageNum;
      } else {
        currentPage--;
      }
      showPage(currentPage);
    });

    $(rightBtn).on("click", function() {
      if (currentPage === pageNum) {
        currentPage = 0;
      } else {
        currentPage++;
      }
      showPage(currentPage);
    });
  }
};

//轮播图js
common.scroll = {
  /*
   * 图片滚动切换
   * 使用方法：scroll_item(每页显示个数,左间距,上一个,下一个,按钮，图片个数)
   */
  scrollItem: function(
    psize,
    cli_left,
    cli_rig,
    scr_num,
    pic_num,
    offset,
    gutter,
    show_num = true
  ) {
    var pageSize = psize || 4;
    var currentPage = 0;
    cli_rig = cli_rig || ".icon-scr-right";
    var len = $(pic_num).find(".box_item").not('.hot-deal-hidden').length;
    var page_number = Math.ceil(len / pageSize);
    var srcItem = $("#src_item1");
    if (!srcItem || srcItem.length == 0) return;
    offset = offset || 0;
    gutter = gutter || 0;
    var leftPx = $("#src_item1").offset().left;
    var rightPx = $(cli_rig).offset().left;

    if (psize != null) pageSize = psize;

    function afterScroll (page) {
      $(scr_num)
        .children("span.selected")
        .removeClass("selected");
      currentPage = page;
      var pageid = 1 + parseInt(page);
      $(scr_num)
        .children("#view" + pageid)
        .addClass("selected");

      $(pic_num)
        .find(".box_item")
        .each(function() {
          var tleft = $(this).offset().left;
          if (tleft >= leftPx && tleft <= rightPx) {
            var img = $(this).find("img");
            if (img.attr("data-original") != "") {
              img.attr("src", img.attr("data-original"));
              img.attr("data-original", "");
            }
          }
      });
    }

    var showPage = function(page) {
      var unitwidth =
        $(pic_num)
          .find(".box_item")
          .first()
          .width() + gutter || 0;
        var currentLeft = page * pageSize;
        $(pic_num).css('left', -1 * (unitwidth * currentLeft) + offset || 0);
        afterScroll(page);
    };

    // for (var num_dot = 1; num_dot <= page_number; num_dot++) {
    //   var num_page = num_dot - 1;
    //   var num_page_text = show_num ? (num_page + 1) : '';
    //   $(scr_num).append(
    //     "<span di-auto='#src_item1 .downContent' id=view" +
    //       num_dot +
    //       " page=" +
    //       num_page +
    //       ">" +
    //       num_page_text +
    //       "</span>"
    //   );
    // }

    $(cli_rig).on("click", function() {
      if (currentPage < page_number - 1) {
        showPage(currentPage + 1);
      } else {
        showPage(0);
      }
    });

    $(cli_left).on("click", function() {
      if (currentPage > 0) {
        showPage(currentPage - 1);
      } else {
        showPage(page_number - 1);
      }
    });

    $(scr_num)
      .children("span")
      .on("click", function() {
        var page = $(this).attr("page");
        showPage(parseInt(page));
      });
    showPage(0);
  },
  //轮播图tab
  tabBtn: function() {
    $("#scroll_tit li").on("click", function() {
      //ga 统计代码
      window.ga4 && ga4("send", {
          hitType: "event",
          eventCategory: "dm_home_hotpicks",
          eventAction: "switch_category",
          //                eventLabel: $(this).find('span').text().trim().toLowerCase().replace(/&/,'').replace(/[\s\n\r]+/g, '_'),
          eventLabel: $(this)
            .find("span")
            .text()
            .trim(),
          eventValue: 1
        });

      $(this)
        .addClass("cur")
        .siblings("li")
        .removeClass("cur");
      var index = $(this).index();
      $(".scr_new")
        .eq(index)
        .show()
        .siblings(".scr_new")
        .hide();
    });
  }
};

//页面中右侧插件js
common.right = {
  /*热门排行榜click rank*/
  topTab: function(title, content) {
    $(title).on("mouseenter", function() {
      $(this)
        .addClass("cur")
        .siblings()
        .removeClass("cur");
      var thisindex = $(this).index();
      $(content)
        .eq(thisindex)
        .show()
        .siblings()
        .hide();
    });
  },

  // 海淘精华攻略
  haiTao: function(shortId, lang) {
    if (lang == "cn") {
      $("#haiTao").html(
        '<div class="title"><h3>海淘精华攻略</h3></div>' +
          '<div class="content">' +
          '   <ul class="htjh">' +
          '       <li><a href="http://cn.dealmoon.com/Haitao-Guide-Various-Freebies/467736.html" target="_blank" title="海淘购物教程" rel="nofollow"><i></i>海淘购物教程</a></li>' +
          '       <li class="narrow zhifu"><a href="http://cn.dealmoon.com/Kaishihaitao-Various-Freebies/468546.html" target="_blank" title="海淘支付教程" rel="nofollow"><i></i>海淘支付教程</a></li>' +
          '       <li class="zhuanyun"><a href="http://cn.dealmoon.com/Buying-Guide/zhuanyun-zhuanyun/532405.html" target="_blank" title="海淘转运教程" rel="nofollow"><i></i>海淘转运教程</a></li>' +
          '       <li class="narrow shenbao"><a href="http://cn.dealmoon.com/Buying-Guide/Tax-tax/525672.html" target="_blank" title="申报和关税" rel="nofollow"><i></i>申报和关税</a></li>' +
          '       <li class="weiquan"><a href="http://cn.dealmoon.com/Haitao-haitao/550155.html" target="_blank" title="海淘攻略合集" rel="nofollow"><i></i>海淘攻略合集</a></li>' +
          '       <li class="narrow qita"><a href="http://cn.dealmoon.com/HAITAO-Size-Chart-Various-Freebies/469245.html" target="_blank" title="海淘尺码对照表" rel="nofollow"><i></i>海淘尺码对照表</a></li>' +
          "   </ul>" +
          "</div>"
      );
    }
  },

  //右侧悬浮热门商家和广告
  // footerObj: 距底部元素
  // topGap fix距顶部Dom距离
  adFloat: function(footerObj, topGap = 18) {
    if (common.right.adFloatInited) {
      return;
    }
    common.right.adFloatInited = true;
    setTimeout(function() {
      var $adfloat = $("#scroll-fix");
      var adParentOffsetTop = 0;
      try {
        adParentOffsetTop = $adfloat.parent().offset().top;
      } catch (error) {
      }
      if ($adfloat.length > 0) {
        var insertDom = $("<div></div>");
        $adfloat.before(insertDom);
        var ad_floatH = $adfloat.outerHeight(true);

        var css_added = false;
        footerObj = footerObj || $("footer");
        $.dmscroll(function() {
          var lFraHei = $(".area_left, .dm-sg-left").height();
          var rFraHei = $(".area_right, .dm-sg-right").height();
          var rFraLeft = $adfloat[0].getBoundingClientRect().left;
          var nav_fixed = $(".nav_fixed, .dm-post-nav-box").height() ||
            $('.collection-subnav-scroll--fixed').height() ||
            $('#new-guide-category .top-categories').height() ||
            $('.product-article-fixed').height() ||
            0;
          var floatStart = insertDom.offset().top;
          var footerTop = footerObj.offset().top;
          var footerADHeight = $('#deal-detail-bottom-ads').height() || 0;
          var footerHeight = footerObj.height() + (footerADHeight ? (footerADHeight + 30) : 0);
          var adFloatHeight = $adfloat.height();

          var bodyHei = $(document).height(); //页面高度

          var floatTz = floatStart - nav_fixed;
          var wscrollTop = $(window).scrollTop();
          var offHeight = bodyHei - wscrollTop - ad_floatH; //如果窗口高度-该元素高度-底部高度=负数，那么就是说页面高度不够该元素需要设置top=offHeight

          if (lFraHei < rFraHei) return;
          if (offHeight < 0) {
            if (css_added == false) {
              $adfloat.css({ position: "fixed", top: offHeight, bottom: 'auto', left: rFraLeft });
              css_added = true;
            }
            return;
          }

          if (wscrollTop > floatTz) {
            if (css_added == false) {
              $adfloat.css({
                position: "fixed",
                zIndex: 1,
                top: nav_fixed + topGap,
                bottom: "auto"
              });
              css_added = true;
            }
            // if (wscrollTop > footerTop - adFloatHeight) {
            //   $adfloat.css({
            //     position: "fixed",
            //     top: 'auto',
            //     bottom: footerHeight + topGap
            //   });
            // } else {
            //   $adfloat.css({
            //     position: "fixed",
            //     top: nav_fixed + topGap,
            //     bottom: "auto"
            //   });
            // }
            $adfloat.css({
              position: "fixed",
              zIndex: 1,
              top: nav_fixed + topGap,
              left: rFraLeft,
              bottom: "auto"
            });
          } else {
            if (css_added == true) {
              $adfloat.removeAttr("style");
              css_added = false;
            }
          }
        });
      }
    }, 10);
  },

  //newsletter订阅输入框
  newsletter: function() {
    if (window.newsletterEnabled) {
      return;
    }
    window.newsletterEnabled = true;
    //newsletter
    $(".news_input").on("focus", function() {
      $(this)
        .parents(".news_input_box")
        .addClass("focus_input");
      var textVal = $(this).val();
      if (textVal == this.defaultValue) {
        $(this).val("");
      }
    });
    $(".news_input").on("blur", function() {
      $(this)
        .parents(".news_input_box")
        .removeClass("focus_input");
      if (window.dmLang == "cn") {
        var hint = "输入您的邮件地址";
      } else {
        var hint = "Your email address";
      }
      //重新显示提示
      if ($(".news_input_box .news_input").val() == "") {
        $(".news_input_box .news_input").val(hint);
      }
    });
  },
  //点击订阅
  newsletterBtn: function() {
    if (window.newsletterBtnEnabled) {
      return;
    }
    window.newsletterBtnEnabled = true;
    $(".news_input_box").on("click", ".focus_btn", function() {
      //获取email
      var email = $(this)
        .parents(".news_input_box")
        .find(".news_input")
        .val();
      var hint = "";
      //判断输入框邮箱
      if ($(".news_input_box .news_input").val() != "") {
        //是否是邮箱
        if (!isEmail(email)) {
          if (window.dmLang == "cn") {
            hint = "请输入有效的email地址";
          } else {
            hint = "Please input a valid email.";
          }

          //重新显示提示
          $(".news_input_box .news_input").val(hint);
        } else {
          //发送ajax
          $.post(
            common.email_url,
            {
              email: email,
              src: "dmweb",
              type: "api",
              lang: window.dmLang,
              subPlatform: 2
            },
            function(data) {
              if (data.data.status == "success" || data.data.status === true) {
                if (window.dmLang == "cn") {
                  hint = "订阅成功！";
                } else {
                  hint = "Subscription success!";
                }
              } else {
                if (window.dmLang == "cn") {
                  hint = "订阅失败，请联系系统管理员！";
                } else {
                  hint = "Subscription failed!";
                }
              }

              //重新显示提示
              $(".news_input_box .news_input").val(hint);
            },
            "json"
          );
        }
      } else {
        //删除添加的class
        $(this)
          .parents(".news_input_box")
          .removeClass("focus_input");
        $(".news_btn").removeClass("focus_btn");
        //根据语言判断提示
        if (window.dmLang == "cn") {
          var hint = "输入您的邮件地址";
        } else {
          var hint = "Your email address";
        }
        //重新显示提示
        $(".news_input_box .news_input").val(hint);
      }
    });
  },

  //newsletter回车事件
  newsletterKeyPress: function() {
    if (window.newsletterKeyPressEnabled) {
      return;
    }
    window.newsletterKeyPressEnabled = true;
    $(document).on("keypress", function(e) {
      // 回车键事件
      if (e.which == 13 && $(".news_input:focus").length > 0) {
        var obg = $(".news_input:focus");

        obg
          .parent()
          .find(".focus_btn")
          .trigger("click");
        return false;
      }
    });
  },
  //今日热点和返回顶部切换
  todyHot: function() {
    if (window.todayHotEnabled) {
      return;
    }
    window.todayHotEnabled = true;
    var todayHot = $("#todayHot");
    var $todayHots = $('.todayHot');
    var wechatBox = $("#wechatBox");
    var standaloneQrCode = $('#today-hot-standalone-qr-code');
    var wecharBoxBtn = $("#wechatBox .btn");
    var todayHotJubao = $("#today-hot-jubao");
    var wH = 450; // $(window).height(); //窗口高度

    // 非固定底栏的页面，右侧栏不要在页面滚动到底部时，设置右侧栏 margin-bottom 避免右侧栏盖到底栏上
    // var footer = document.getElementById('newFooter') || document.querySelector('footer');
    // function todayHotOnScroll () {
    //   if (footer && $todayHots.length > 0) {
    //     var footerBounds = footer.getBoundingClientRect();
    //     var clientHeight = document.documentElement.clientHeight;
    //     var footerViewHeight = clientHeight - footerBounds.top;
    //     var todayHotMarginBottom = !footer.classList.contains('fixed_footer') && footerViewHeight > 0 ? footerViewHeight : 0;
    //     [].forEach.call($todayHots, function (t) {
    //       t.style.marginBottom = todayHotMarginBottom + 'px';
    //     });
    //   }
    // }
    // todayHotOnScroll();
    // window.addEventListener('scroll', todayHotOnScroll);
    // window.addEventListener('resize', todayHotOnScroll);
    // window.addEventListener('load', todayHotOnScroll);
    var todayShow = function(wScorll, wH) {
      //首页、分类列表页，回顶效果
      if (todayHot.length > 0) {
        if (wScorll > wH) {
          // 滚动一屏
          todayHot.show();
          standaloneQrCode.removeClass('only-qr-code');
          todayHot.removeClass('only-report');
        } else {
          standaloneQrCode.addClass('only-qr-code');
          todayHot.addClass('only-report');
        }
      }
    };

    function getCookieToken() {
      var token;
      try {
        token = /(^|[\s;]+)usertoken=([^;]+)/.test(document.cookie)
          ? decodeURIComponent(RegExp.$2)
          : '';
      } catch (error) {}
      return token || '';
    }

    function getGaEventParameters (eventCategory, eventAction) {
      var userToken = getCookieToken();
      return {
        hitType: 'event',
        eventCategory: eventCategory,
        eventAction: eventAction,
        dimension3: window.customPvParam && window.customPvParam.dimension3 ? window.customPvParam.dimension3 : '',
        dimension17: 'dm',
        dimension19: window.customPvParam && window.customPvParam.dimension19 ? window.customPvParam.dimension19 : '',
        dimension22: window.dmLang || 'cn',
        eventLabel: 'yh:' + (userToken && /(\d+)\|/.test(userToken) ? RegExp.$1 : '0') + '|pf:pc|pgn:home'
      }
    }

    var todayHotFeedback = document.getElementById('today-hot-feedback');
    if (todayHotFeedback) {
      todayHotFeedback.addEventListener('click', function () {
        // 添加右侧栏用户反馈按钮埋点信息
        window.ga4 && ga4('send', getGaEventParameters('dm-floatingbar-click', 'click-dm-home-right-floatingbar-userfeedback'));
      });
    }

    var wechatShow = function(wScorll, wH) {
      //详情页回顶效果
      if (wechatBox.length > 0) {
        var wW = $(window).width();
        if (wScorll > wH) {
          //滚动一屏
          if (wW > 1330) {
            wechatBox.fadeIn(200);
            wecharBoxBtn.fadeIn(200);
            // rtop_btn.fadeOut(200);
          } else {
            wechatBox.fadeOut(200);
            // rtop_btn.fadeIn(200);
          }
        } else {
          if (wW > 1330) {
            wechatBox.fadeIn(200);
            wecharBoxBtn.fadeOut(200);
            // rtop_btn.fadeOut(200);
          } else {
            wechatBox.fadeOut(200);
            // rtop_btn.fadeIn(200);
          }
        }
      }
    };

    var checkShow = $.makeLazy(
      function() {
        var wScorll = $(window).scrollTop();
        todayShow(wScorll, wH);
        wechatShow(wScorll, wH);
      },
      100,
      10
    );
    $.dmscroll(checkShow);
    $(window).on("resize", checkShow);
  },
  //GO TOP
  goTop: function() {
    if (window.goTopEnabled) {
      return;
    }
    window.goTopEnabled = true;
    $(document).on("click", ".toplink", function() {
      $("html, body").animate(
        {
          scrollTop: 0
        },
        500
      );
    });
  },
  //右侧悬浮导航栏
  //默认在main.tpl调用该方法写入内容
  //需调用此方法写入html内容，并且调用common.right.todyHot方法监听滚动才可生效
  floatNav: function(shortId, islocal, lang) {
    //处理现有版本处理不需要写入导航栏的页面
    const noNavMap = {
      alert: 1,
      "contact-us": 1,
      compilation: 1,
      careers: 1,
      disclaimer: 1,
      integral: 1
    };
    //这几个类型下面的页面的右侧悬浮栏需要新的样式和逻辑  所以单独处理
    const newNavMap = {
      home: 1,
      hot: 1,
      categoryz: 1,
    };
    var siteCode = ($.cookie("CC") || "US").toLowerCase();
    var $todayHot = $("#todayHot");
    if (noNavMap[shortId] || !$todayHot.length) {
      return;
    }

    const cnSite = lang !== "en";
    let textList = [
      "Follow our Wechat",
      "Contact Us",
      "Hottest",
      "Beauty",
      "Top",
      "Download App",
      "Wechat Scan Open Mini Program"
    ];
    if (cnSite) {
      textList = [
        "关注公众号<br>看独家内容",
        "联系我们",
        "热门折扣",
        "美妆护肤",
        "回到顶部",
        `扫码下载APP`,
        "微信扫码打开小程序"
      ];
    }

    //回到顶部
    const goTop = `<div class="btn cnBtn"><a class="toplink top_color_set" href="javascript:;">${textList[4]}<em class="go-top-icon"></em></a></div>`;
    //中文站点local单独处理
    if (cnSite && islocal) {
      $("#todayHot")
        .css({ "border-radius": "5px" })
        .html(goTop);
      return;
    }

    // 扫描APP下载
    let downloadApp = `<div class="qrcode-box"><i class="qrcode-box-img j-qrcode"><em class="${siteCode}"></em></i><p class="qrcode-box-title">${textList[5]}</p></div>`;
    if(location.host.indexOf('dealmoonuk.com') >= 0){
      downloadApp = `<div class="qrcode-box"><i class="qrcode-box-img j-qrcode"><em class="ukcn"></em></i><p class="qrcode-box-title">${textList[5]}</p></div>`;
    }
    // 扫码打开小程序
    let wechatBox = "";
    let wxBox = `<div class="wechat_btn"><em class="icon-wechat-btn"></em><p>${textList[0]}</p></div>`;
    if (siteCode !== "us") {
      //非美国站（只存在中文情况）,不添加微信二维码跳转链接
      wechatBox = wxBox;
    } else {
      if (cnSite) {
        wxBox =
          '<div class="wechat-box"><div class="code-public"><div class="code-img"></div><span>关注公众号<br/>看独家内容</span></div></div>';
      }
      wechatBox = `<a href="${
        common.wechat_url
      }" target="_blank" rel="nofollow" ${
        cnSite
          ? ' gatrk="followwechat_new" class="ga_event_statistics_right_nav"'
          : ""
      }>${wxBox}</a>`;
    }

    //组合html数据
    var rightNavHtml = [];
    if (siteCode != 'us' ) {
      rightNavHtml.push(`${wechatBox}<ul>`);
    }
    //客户端
    let dprefix = "";
    if (siteCode === "us") {
      dprefix = `//${location.host.replace(/^haitao\./, "www.")}/${
        cnSite ? "cn" : "en"
      }`;
    }

    dprefix = `//${location.host.replace(/^sso\./, "www.")}/${
      cnSite ? "cn" : "en"
    }`;
    if (cnSite) {
      rightNavHtml.push(`<li class="today_app">
                <a href="${dprefix}/app-download" class="th_07 ga_event_statistics_right_nav" rel="nofollow" target="_blank" gatrk="mobile_new">
                    <span>APP</span>客户端
                    <div class="j_right_nav_download_qrcode qrcode"><span>扫码下载APP</span></div>
                </a>
            </li>`);
    } else {
      rightNavHtml.push(`<li class="today_app">
                <a href="${dprefix}/app-download" class="th_07" rel="nofollow" target="_blank">
                    Mobile
                    <div class="j_right_nav_download_qrcode qrcode"><span>Download APP</span></div>
                </a>
            </li>`);
    }
    //联系我们
    rightNavHtml.push(
      `<li><a href="${common.contact_us_url}" rel="nofollow" target="_blank" ${
        cnSite
          ? 'class="th_00 ga_event_statistics_right_nav" gatrk="contactus_new"'
          : 'class="th_00"'
      }>${textList[1]}</a></li>`
    );
    //今日热门
    rightNavHtml.push(
      `<li><a href="${common.hot_url}" rel="nofollow" target="_blank" ${
        cnSite
          ? 'class="th_01 ga_event_statistics_right_nav" gatrk="hottest_new"'
          : 'class="th_01"'
      }>${textList[2]}</a></li>`
    );
    //美容保健
    rightNavHtml.push(
      `<li><a href="${
        common.category_beauty_url
      }" rel="nofollow" target="_blank" ${
        cnSite
          ? 'class="th_02 ga_event_statistics_right_nav" gatrk="beauty_new"'
          : 'class="th_02"'
      }>${textList[3]}</a></li>`
    );
    // 电子电脑
    if (cnSite) {
      rightNavHtml.push(
        `<li><a href="${common.category_computer_url}" class="th_04 ga_event_statistics_right_nav" rel="nofollow" target="_blank" gatrk="secondmarket_new">电子电脑</a></li>`
      );
    }
    //信用卡
    if (window.fromChinaSite) {
      // 中国站
      common.creditcardLink = ''
      // common.creditcardLink =
      //   '<li><a href="//www.dealmoon.cn/event/finance" class="th_05" rel="nofollow" target="_blank">信用卡</a></li>';
    } else if (!cnSite) {
      common.creditcardLink =
        '<li><a href="//www.dealmoon.com/credit-cards" class="th_05" rel="nofollow" target="_blank">Credit Card</a></li>';
    } else if (siteCode === "ca") {
      common.creditcardLink =
        '<li><a href="//www.dealmoon.ca/credit-cards" class="th_05 ga_event_statistics_right_nav" rel="nofollow" target="_blank" gatrk="creditcard_new">信用卡</a></li>';
    }
    if (common.creditcardLink) {
      rightNavHtml.push(common.creditcardLink);
    }
    // 电子电脑
    if (!cnSite) {
      rightNavHtml.push(
        `<li><a href="${common.category_computer_url}" class="th_04 ga_event_statistics_right_nav" class="th_06" rel="nofollow" target="_blank" gatrk="RightNav_BBS">Electronics</a></li>`
      );
    }
    // 举报
    if (cnSite) {
      rightNavHtml.push(
        `<li><a id="today-hot-jubao" href="javascript:;" rel="nofollow">举报</a></li>`
      );
    }
    // 切换中文/English
    let changeLink = common.englishLink;
    
    if (!cnSite) {
      changeLink = `<li><a onclick="window.topbar&&window.topbar.recordLang(\'cn\')" href="${common.home_cn_url}" class="th_09 change-language-cn" rel="nofollow" target="_blank"><em class="icon-language-right"></em></a></li>`;
    }
    rightNavHtml.push(changeLink);

    // 回到顶部
    rightNavHtml.push(`</ul>${goTop}`);

    // 折扣详情页仅展示下载和回顶，并且需要初始化
    if (shortId == 'guide' || shortId == 'public-test' || shortId == 'search' || shortId == 'baoliao' || shortId == 'category' || shortId == 'rank' || shortId == 'credit-card' || shortId == 'brands' || shortId == 'product' || shortId == 'store' || shortId == "deal" || shortId == "guide" || shortId == "post" || newNavMap[shortId]) {
      rightNavHtml = [];
      var showJubao = $('footer .show_jubao').length;
      var wordSet = {
        cn: ['举报', '用户<br/>反馈', '回到<br/>顶部'],
        en: ['', '', 'Top']
      };
      var hasJubaoOrFeedback = false;
      rightNavHtml.push(`<div class="side-new-cont ${lang=='en' ? `side-en` : ``}">`);
      if (newNavMap[shortId]) {
        if (showJubao && wordSet[lang][0] && window.visitCountry === 'CN') {
          rightNavHtml.push(`<p class="bg9"><a id="today-hot-jubao" href="javascript:;" rel="nofollow">${wordSet[lang][0]}</a></p>`);
          hasJubaoOrFeedback = true;
        } else if (wordSet[lang][1]) {
          rightNavHtml.push(`<p class="bg9"><a id="today-hot-feedback" href="/${lang == 'en' ? 'en' : 'cn'}/comments" target="_blank" rel="nofollow">${wordSet[lang][1]}</a></p>`);
          hasJubaoOrFeedback = true;
        }
      }
      // 首页不显示举报和反馈
      if (shortId === 'home' && window.visitCountry !== 'CN' && lang != 'en') {
        showJubao = false;
        rightNavHtml.pop();
        hasJubaoOrFeedback = false;
      }
      rightNavHtml.push(`<div class="bg4 toplink">${wordSet[lang][2]}</div></div>`);
      $todayHot.addClass('new_side1');
      if (showJubao && newNavMap[shortId]) {
        $todayHot.addClass('new_side_jubao');
      }
      if (shortId == 'guide' || shortId == 'public-test' || shortId == 'search' || shortId == 'baoliao' || shortId == 'category' || shortId == 'rank' || shortId == 'credit-card' || shortId == 'search' || shortId == 'post' || shortId == 'brands' || shortId == 'store' || shortId == 'product' || shortId == 'deal' || (location.pathname === '/' && window.visitCountry !== 'CN')) {
        $todayHot.after(`<div id="today-hot-standalone-qr-code" class="todayHot only-qr-code pw${window.pageInfo['pageWidth']}${hasJubaoOrFeedback ? '' : ' only-back-top'}">${downloadApp}</div>`);
      }
      if (shortId == 'guide' || shortId == 'public-test' || shortId == 'search' || shortId == 'baoliao' || shortId == 'rank' || shortId == "product" || shortId == "guide" || shortId == 'credit-card' || shortId == "post" || shortId == 'search') {
        $todayHot.addClass('fix-status3');
        $('.footer_wrap .download_app').hide();// 详情页隐藏底栏的下载二维码
        $('.footer_wrap .right_info .icon-phone, .footer_wrap .right_info .icon-lang-cn, .footer_wrap .right_info .icon-lang-en').hide();
      }
      setTimeout(() => {
        common.rightFloatGenQrCode({
          $target: $('#today-hot-standalone-qr-code .j-qrcode')
        });
      }, 0);
    }
    $todayHot.html(rightNavHtml.join(""));
  },
  footerQrcode: function(){
    if(window.footerQrcodeAdjustLinkUrl){
      const siteCode = ($.cookie("CC") || "US").toLowerCase();
      const $obj = $('#newFooter .qrcode_wrap');
      const adgroup = window.pageInfo['adjustLabel'] ? window.pageInfo['adjustLabel'].replace(/[_\d]*/g, '') : location.pathname.substring(1).replace(/\//g, '-');
      if(location.host.indexOf('dealmoonuk.com') >= 0){
        $obj.find('.middle_icon').addClass('ukcn')
      }else{
        $obj.find('.middle_icon').addClass(siteCode);
      }
      $obj.qrcode({
        text: `${window.footerQrcodeAdjustLinkUrl}?campaign=${common.getAdjustCampaign()}&adgroup=${adgroup}${window.pageInfo['adjustLabel'] ? '&label=' + window.pageInfo['adjustLabel'] : ''}`,
        correctLevel: 0,
        width: 140,
        height: 140
      });
    }
  },
  toggleBannerDownload: () => {
    setTimeout(() => {
      if ($('#today-hot-standalone-qr-code').length) {
        $('.banner-download').remove();
      } else {
        $('.banner-download').addClass('show');
      }
    }, 1);
  }
};


/*
 * {id} number 折扣id
 * {callback} function 回调函数
 * {limit} number 每个组返回单品限制数
 */
// common.getDpsItem = function(id, callback, limit) {
//   $.ajax({
//     type: "GET",
//     url: "/www/deal/get-dps-async",
//     data: { dealId: id, limit, lang: window.dmLang },
//     dataType: "JSON",
//     success: callback
//   });
// };

//商品中的单品
// common.single = {
//   //点击显示更多单品
//   moreShow: function() {
//     $("body").on("click", ".dp_tj .dpc_b", function() {
//       var obj = $(this).parents(".mlist");
//       var id = obj.attr("data-id");
//       var container = $(this).siblings(".dpc");
//       var count = $(this)
//         .parent(".dp_tj")
//         .data("count");
//       var s = $(this).find("span:first");

//       var limit =
//         obj.data("group-limit") > 0 ? obj.data("group-limit") : undefined;

//       if (!this.getDpsItemLock && parseInt(count) > 3) {
//         this.getDpsItemLock = true;
//         var loadingHtml =
//           '<div class="loading_deal" style="background:none;width:100%;"><div class="wrapper" style="background: #fff;"><div class="line-spin-fade-loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>Loading...</div></div>';
//         container.find(".tw:last").after(loadingHtml);

//         common.getDpsItem(
//           id,
//           function(res) {
//             if (res.msg == "success") {
//               container.find(".loading_deal").remove();
//               container.append(res.data.html);
//             }
//           },
//           limit
//         );
//         $(this).addClass("dpc_b_up");
//       } else {
//         var b = $(this)
//           .parent()
//           .find(".tw:gt(2)");
//         if (b.css("display") == "none") {
//           b.slideDown(400);
//           $(this).addClass("dpc_b_up");
//         } else {
//           b.slideUp(400);
//           $(this).removeClass("dpc_b_up");
//           $(".sp-fix-wrap")
//             ? $(".sp-fix-wrap").removeClass("sp-fix-show")
//             : null;
//         }
//       }
//     });
//   }
// };

common.dealDetail = {
  // share ajax
  shareAction: function(target, params) {
    // 分享按钮点击事件, 包括微博、微信、QQ空间和Facebook
    $.ajax({
      type: "POST",
      url: common.dealDetail.shareStatisticUrl,
      data: params,
      dataType: "JSON",
      success: function(res) {
        if (res.msg == "success") {
          target.html(res.data.total);
        }
      }
    });
  },

  shareArea: function() {
    $("body").on("click", ".share_btn", function(e) {
      var $dom = $(e.target).closest(".share_btn");
      var id = $dom.data("id");
      var pageLink = $dom.data("pagelink") || window.location.href;
      var imgUrl = $dom.data("imgurl");
      var title = $dom.data("title");
      var contentType = $dom.data("contentType");
      var position = $dom.data("position") || "up";

      $dom.dmShare({
        title: title,
        pageLink: pageLink,
        imgUrl: imgUrl,
        id: id,
        clazz: ".share_btn",
        toggleOnClick: true,
        contentType: contentType,
        contentId: id,
        position: position,
        callback: function(res) {
          $dom.addClass("hasnum");
          $dom.each(function(index, node) {
            var $num = $(node).find(".shareNums-" + id),
              num = parseInt($num.text() || 0);
            $num.show().text(++num);
          });

          common.dealDetail.shareAction(
            $(
              "em.shareNums-" +
                $(res.target)
                  .attr("statrk")
                  .replace(/\D/g, "")
            ),
            {
              id: $(res.target)
                .attr("statrk")
                .replace(/\D/g, ""),
              type: contentType,
              platform: res.type
            }
          );
        }
      });
    });
  },

  _makeUrlPartsBuyingGuide: function(item) {
    //title
    var title = "",
      pageLink = "",
      imgUrl = "";
    var title = item.find("h1>a").html();
    var reg = /<[^<>]+>/g; //过滤点html标签
    title = title.replace(reg, "");
    pageLink = window.location.href;

    imgUrl = item.find(".buyingguide-img").val();

    return { title: title, pageLink: pageLink, imgUrl: imgUrl };
  },

  _makeUrlParts: function(item) {
    //title
    var title = "",
      pageLink = "",
      imgUrl = "";

    var titlePart = item.find(".mtxt h2 a span");
    if (titlePart.length > 0) {
      titlePart.each(function() {
        var str = $(this)
          .text()
          .replace(/\(.+?\)/m, " ");
        str = str.replace(/\s{2,}/g, "");
        str = str.replace(/(\n\r|\r\n|\r|\n)+/g, "");

        title += str;
      });
    } else {
      item.find(".mtxt h1 a span").each(function() {
        var str = $(this)
          .text()
          .replace(/\(.+?\)/m, " ");
        str = str.replace(/\s{2,}/g, "");
        str = str.replace(/(\n\r|\r\n|\r|\n)+/g, "");

        title += str;
      });
    }

    //pageLink
    /*pageLink = item.find('.img_wrap a ').attr('href');*/
    pageLink = item.find(".img_wrap a input ").val();
    //            pageLink = window.dmLang == 'en'? 'http://w.dealmoon.com/' + pageLink: 'http://cn.dealmoon.com/' + pageLink + '?lang=cn';
    // the following is for production
    //                pageLink = window.dmLang == 'en'? 'http://www.dealmoon.com/' + pageLink: 'http://cn.dealmoon.com/' + pageLink;
    if (pageLink == null || pageLink.substring(0, 4) != "http") {
      pageLink = topbar.host + pageLink;
    }

    imgUrl = item.find(".img_wrap img").attr("src");

    return { title: title, pageLink: pageLink, imgUrl: imgUrl };
  },

  emailShare: function() {
    common.emailShare = null;
    common.defaultTo =
      "Use a comma between addresses, e.g. jean@email.com, roger@mail.com";

    //        $('a.mail_btn').attr("href",'#sharediv').attr('title', 'Share with friends');
    $("body").on("click", "a.icon-mail", function(event) {
      showDlg(this);
      $(".dialog-overlay").on("click", function() {
        common.emailShare.hide();
      });
      return false;
    });
    //Press Escape event!
    $(document).on("keypress", function(e) {
      if (e.keyCode == 27) {
        if (common.emailShare != null) common.emailShare.hide();
      }
    });

    $("body").on("click", "input.mail_share", function() {
      if (sendmail()) {
        common.emailShare.hide();
      }
    });

    //bind email send

    function showDlg(obj) {
      if (common.emailShare == null) {
        var dlgHtml = $("#sharediv").html();
        $("#sharediv").empty();
        common.emailShare = new Dialog(
          { type: "text", value: dlgHtml },
          {
            beforeClose: function() {
              common.emailShare.hide();
              return false;
            }
          }
        );
      }
      common.emailShare.show();
      //如果是buying_guide
      if ($(".mlist").hasClass("article_buyingguide")) {
        var res = common.dealDetail._makeUrlPartsBuyingGuide(
          $(obj).closest(".mlist")
        );
        title = res.title;
        link = res.pageLink;
      } else {
        var dealblock = $(obj)
          .closest(".mlist")
          .find(".mtxt");
        var title =
          dealblock.find("h2").length > 0
            ? dealblock.find("h2").text()
            : dealblock.find("h1").text();
        var link = $(obj).attr("l");
        if (link == null)
          link =
            dealblock.find("h2").length > 0
              ? dealblock.find("h2 a").attr("href")
              : dealblock.find("h1 a").attr("href");

        if (link.indexOf("http://") < 0)
          link = "http://www.dealmoon.com" + link;

        title = unescape(title);
        title = title.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ");
      }

      var content =
        "Hi there, \n\nI found this deal from dealmoon.com. \n\nTitle: " +
        title;

      content += "\nLink: " + link + "\n\n-- Cheers,";
      if (/MSIE (\d+\.\d+);/.test(navigator.userAgent))
        $("#emailmsg").attr("value", content);
      else $("#emailmsg").html(content);
    }

    function sendmail() {
      var strfromname = $("#emailname").attr("value");
      var strfrom = $("#emailfrom").attr("value");
      var strto = $("#emailto").attr("value");
      var strcontent = $("#emailmsg").attr("value");
      var sendcopy = $("#emailsendcopy").attr("checked");
      if (!isEmail(strfrom) || !isEmail(strto)) {
        alert("Please input a valid email.");
        return false;
      }

      if (strfromname != null) strcontent += "\n" + strfromname;
      else strcontent += "\n" + strfrom;

      if (strfromname == null || strfromname == "") strfromname = strfrom;

      var strtitle =
        encodeURIComponent(strfromname) +
        " wants you to see this deal at Dealmoon.com";
      var strcontent = encodeURIComponent(strcontent);

      $.post(
        common.email_share_url,
        {
          title: strtitle,
          content: strcontent,
          from: strfrom,
          fromname: strfromname,
          to: strto
        },
        function(data) {
          if (data.status == 1) {
            alert("The email has been sent to " + strto + ".");
          } else {
            alert(data.msg);
          }
        },
        "json"
      );

      if (sendcopy) {
        $.post(
          common.email_share_url,
          {
            title: strtitle,
            content: strcontent,
            from: "admin@dealmoon.com",
            fromname: "Dealmoon.com",
            to: strfrom
          },
          function(data) {
            if (data.status != 1) {
              alert(data.msg);
            }
          },
          "json"
        );
      }

      return true;
    }
  },

  reportError: function() {
    $("body").on("click", "a.report_error", function() {
      $("#errResp").html("");

      showDialog(this);

      $(".dialog-overlay").on("click", function() {
        common.report_error_html.hide();
      });

      return false;
    });

    $("body").on("click", "input.report_error_submit", function() {
      reportErrorForm();
      return false;
    });

    $("body").on("click", "input.report_error_cancel", function() {
      common.report_error_html.hide();
      return false;
    });

    $(document).on("keypress", function(e) {
      if (e.keyCode == 27) {
        if (common.report_error_html != null) {
          common.report_error_html.hide();
        }
      }
    });

    function showDialog() {
      if (common.report_error_html == null) {
        var reportErrorObj = $("#report_error_html");
        var dialogHtml = reportErrorObj.html();
        reportErrorObj.empty();

        var title = dmLang == "en" ? "Report Error" : "报告错误";
        common.report_error_html = new Dialog(
          { type: "text", value: dialogHtml },
          {
            title: title,
            beforeClose: function() {
              common.report_error_html.hide();
              return false;
            }
          }
        );
      }

      common.report_error_html.show();
    }

    function reportErrorForm() {
      var dealId = $('input[name="reportErrorDealId"]').val();
      var errorType = $('input[name="reportErrorProblem"]:checked').val();
      var errorContent = $('textarea[name="reportErrorContent"]').val();

      if (errorType == undefined) {
        if (dmLang == "cn") {
          alert("请选择一个问题");
        } else {
          alert("Please check a problem.");
        }
        return;
      }

      $.post(
        common.report_error_form_url,
        {
          dealId: dealId,
          errorType: errorType,
          errorContent: errorContent,
          userName: topbar.user.userName
        },
        function(result) {
          if (dmLang == "en") {
            if (result.status == 1) {
              $("#errResp").text("Thanks for reporting errors.");
            } else {
              $("#errResp").text("There was a problem with the request.");
            }
          } else {
            if (result.status == 1) {
              $("#errResp").text("谢谢您报告错误");
            } else {
              $("#errResp").text("请求错误");
            }
          }

          setTimeout("common.report_error_html.hide();", 1000);
        },
        "json"
      );
    }
  }
};

//goto跳转翻页
common.gotoPage = function() {
  $("a.okbtn").on("click", function() {
    var goto = $(this)
      .prev()
      .val();

    //默认第一页 且 第一页不要页码
    if (isNaN(goto) || goto == 1) {
      goto = "";
    }
    var reg = /\/\d+$/;
    var matched = location.pathname.match(reg);

    //url中已经有页码则替换
    if (matched) {
      var replaced = location.pathname
        .replace(reg, "/" + goto)
        .replace("//", "/")
        .replace(/\/$/, "");
      location.pathname = replaced;
    }
    //否则加上页码
    else {
      var replaced = (location.pathname + "/" + goto)
        .replace("//", "/")
        .replace(/\/$/, "");

      location.pathname = replaced;
    }
  });
};

//收藏功能
common.favorite = function() {
  //click rank
  var abj = $("body").on("click", ".favorite,.fav_btn", function(e) {
    var btn = $(this);
    // e.stopPropagation();
    //如果已经点过收藏则取消
    if (btn.closest("span").hasClass("faved")) {
      //Lisujing-Ui bookmarked
      common._unfavorite(btn);
    } else if (
      btn.closest("a").hasClass("faved_btn") ||
      btn.closest("a").hasClass("faved")
    ) {
      common._unfavorite(btn);
    } else {
      common._favorite(btn);
    }
  });
};

common._favorite = function(btn) {
  //判断登陆?
  if ($.cookie("usertoken")) {
    //如果没有完成收藏操作则退出操作
    if (!common.is_fav) {
      return;
    }
    //初始化变量
    common.is_fav = false;
    var id = 0;
    //        var isRank = btn.hasClass('favorite');
    var isRank = topbar.curPage == "rank";
    //rank
    if (isRank) {
      id = btn.attr("dealId");
    } else {
      id =
        btn.closest(".mlist").length > 0
          ? btn.closest(".mlist").attr("data-id")
          : btn.closest(".article_buyingguide").attr("data-id");
      //兼容其它
      id = id ? id : btn.closest(".fav_btn").attr("data-id");
    }
    $.post(
      common.fav_url,
      { id: id },
      function(data) {
        if (data.status == 1) {
          $(".isFav-" + id).each(function() {
            btn = $(this);
            //if buying_guide
            // if(btn.closest('.article_buyingguide').attr('data-id')>0){
            //     common._buying_guide_favorite(btn,'favorite'); //收藏后的处理
            // }
            //update style and text

            if (isRank) {
              btn.closest("a").addClass("faved");
              if (window.dmLang == "en") {
                btn.closest("a").attr("title", "Bookmarked");
              } else {
                btn.closest("a").attr("title", "取消收藏");
              }
            } else if (window.dmLang == "en") {
              btn.closest("span").attr("title", "Bookmarked");
            } else if (btn.closest(".mlist").length > 0) {
              btn.find(".fav-content").text("收藏 ");
              btn.closest("span").attr("title", "取消收藏");
            }

            if (isRank) {
              var text = btn.find(".fav-numbers").text();
              var num = parseInt(text);
              if (isNaN(num) || num < 0) {
                num = 0;
              }
              if (text != "9999+" && text != "9999") {
                btn.find(".fav-numbers").text(num + 1);
              } else {
                btn.find(".fav-numbers").text(9999 + "+");
              }
            } else if (btn.closest(".mlist").length > 0) {
              btn.closest("span").addClass("faved");
              var text = btn.find(".fav-numbers").text();
              var num = parseInt(text);
              if (isNaN(num) || num < 0) {
                num = 0;
              }
              if (text != "9999+") {
                btn.find(".fav-numbers").text(num + 1);
              }
              btn.addClass("hasnum");
            } else {
              btn.closest("span").addClass("faved");
              var favNums = parseInt(btn.find(".fav-numbers").text() || 0) + 1;
              if (favNums >= 9999) {
                btn.find(".fav-numbers").text("9999+");
              } else {
                btn.find(".fav-numbers").text(favNums);
              }
            }
          });

          //检测变量请求成功后修改为true
          common.is_fav = true;
        }
        //登陆过期
        else if (data.msg === "token expired") {
          alert(
            window.dmLang == "en"
              ? "login expired, please relogin"
              : "登陆过期, 请重新登陆"
          );
          $("#sign_in_top").click();
        } else {
          alert(data.msg);
        }
      },
      "json"
    );
  } else {
    //pop up the login window
    $("#sign_in_top").click();
  }
};

common._unfavorite = function(btn) {
  if ($.cookie("usertoken")) {
    var id = 0;
    var isRank = topbar.curPage == "rank";
    //rank
    if (isRank) {
      id = btn.attr("dealId");
    } else {
      id =
        btn.closest(".mlist").length > 0
          ? btn.closest(".mlist").attr("data-id")
          : btn.closest(".article_buyingguide").attr("data-id");
      //兼容其它
      id = id ? id : btn.closest(".fav_btn").attr("data-id");
    }
    $.post(
      common.unfav_url,
      { id: id },
      function(data) {
        if (data.status == 1) {
          // if(btn.closest('.article_buyingguide').attr('data-id')>0){
          //     common._buying_guide_favorite(btn,'unfavorite'); //取消收藏后的处理
          // }
          $(".isFav-" + id).each(function() {
            btn = $(this);
            if (isRank) {
              btn.closest("a").removeClass("faved");
              if (window.dmLang == "en") {
                btn.closest("a").attr("title", "Bookmark");
              } else {
                btn.closest("a").attr("title", "收藏");
              }
            } else if (window.dmLang == "en") {
              btn.closest("span").attr("title", "Bookmark");
            } else {
              btn.find(".fav-content").text("收藏 ");
              btn.closest("span").removeAttr("title");
            }

            if (isRank) {
              var text = btn.find(".fav-numbers").text();
              var num = parseInt(text);
              if (isNaN(num) || num < 0) {
                num = 0;
              }
              if (num > 1) {
                if (text != "9999+" && text != "9999") {
                  btn.find(".fav-numbers").text(num - 1);
                } else {
                  btn.find(".fav-numbers").text(9998);
                }
              } else {
                btn.find(".fav-numbers").text("");
              }
            } else {
              btn.closest("span").removeClass("faved");
              var text = btn.find(".fav-numbers").text();
              var num = parseInt(text);
              if (text != "9999+") {
                btn.find(".fav-numbers").text(num > 1 ? num - 1 : "");
              } else {
                btn.find(".fav-numbers").text(9998);
              }
              if (num > 1 && btn.closest(".mlist").hasClass("gongge")) {
                btn.find(".fav-content").text("");
              }
              if (num <= 1) {
                btn.removeClass("hasnum");
              }
            }
          });
          //update style and text
        } else if (data.msg == "token expired") {
          alert(
            window.dmLang == "en"
              ? "login expired, please relogin"
              : "登陆过期, 请重新登陆"
          );
          $("#sign_in_top").click();
        } else {
          alert(data.msg);
        }
      },
      "json"
    );
  } else {
  }
};

common._buying_guide_favorite = function(obj, type) {
  if (type == "favorite") {
    var value = $(obj)
      .find("em")
      .html();
    value = parseInt(value.replace("(", "").replace(")", ""));
    if (value > 0) {
      $(obj)
        .find("em")
        .html("(" + (value + 1) + ")");
    } else {
      $(obj)
        .find("em")
        .html("(" + 1 + ")");
    }
    $(obj)
      .find("i")
      .html("-1");
    $(obj)
      .find(".favContent")
      .html("收藏");
    $(obj).addClass("faved_btn");
  } else {
    var value = $(obj)
      .find("em")
      .html();
    value = parseInt(value.replace("(", "").replace(")", ""));
    if (value > 0) {
      $(obj)
        .find("em")
        .html("(" + (value - 1) + ")");
    } else {
      $(obj)
        .find("em")
        .html("");
    }

    $(obj)
      .find("i")
      .html("+1");
    $(obj)
      .find(".favContent")
      .html("收藏");
    $(obj).removeClass("faved_btn");
  }
};

common.newsletter = {};

common.newsletter.subscribe = function() {
  $(".nowsub .mailgo").on("click", function() {
    var btn = $(this);
    var input = $("#mailadd");

    if (isEmail(input.val())) {
      var form = btn.closest("table").find("form");
      form.append(
        '<input type="hidden" name="email" value="' + input.val() + '">'
      );
      form.append(
        '<input type="hidden" name="lang" value="' + window.dmLang + '">'
      );
      form.append('<input type="hidden" name="subPlatform" value="3">');
      form.submit();
    } else {
      btn
        .closest("tr")
        .prev("tr")
        .show();
    }
  });
};

common.newsletter.unsubscribe = function() {
  const lazyCall = $.makeLazy(function(){
    var euid = $("#euid").val();
    $("#unsub_confirm").submit();
  },1000);
  $(".sure .mailgo").on("click", lazyCall);
};

common.newsletter.feedback = function() {
  $(".unsubok .mailgo").on("click", function() {
    var feedback = $('[name="feedback[]"]:checked,textarea[name="feedback[]"]');
    //var feedback = document.querySelectorAll('[name="feedback[]"]');
    var feedbackStr = "| ";
    feedback.each(function() {
      feedbackStr += $(this).val() + " ";
    });

    $("#email_unsub").append(
      '<input type="hidden" name="feedback" value="' + feedbackStr + '">'
    );

    $("#email_unsub").submit();
  });
};

/*******************comments********************/
common.comment = {};
common.comment.get_en_url = "";
common.comment.get_common_url = "";
common.comment.wrapMap = {
  cn:
    '<div class="mc_list com_list all_comment"><a name="all_comments"></a><div class="mc_list_tit"><h4><span class="change_comments"><a class="lang_opt selected" lg="cn">全部评论(1)</a></span></h4></div></div>',
  en:
    '<div class="mc_list com_list all_comment"><a name="all_comments"></a><div class="mc_list_tit"><h4><span class="change_comments"><a class="lang_opt selected" lg="cn">All Comments (1)</a><em> &nbsp;&nbsp;|&nbsp;&nbsp;<a class="lang_opt " lg="en">English Only (0)</a></em></span></h4></div></div>'
};

/**
 * 对评论内容做简单处理
 * @param {type} data
 * @returns {String|jQuery|$|@exp;_$|Window.$}
 */
common.comment._handleContent = function(data) {
  var html = "";
  if (data.data.html.match(/\S/)) {
    html = $(data.data.html);
    //繁体版处理
    helper.toCHT(html[0]);
  }
  return html;
};

/**
 * 微博评论头像跳转功能
 */
common.comment.toWeibo = function() {
  $("body").on("click", ".to_weibo", function() {
    var fmid = $(this).attr("fmid");
    if (fmid) {
      var weiboUrl = "http://weibo.com/2132734472/" + WeiboUtils.mid2url(fmid);
      window.open(weiboUrl);
    }
  });
};

common.comment.userNameSet = function() {
  var username;
  if (!$.cookie("usertoken")) {
    username = window.dmLang == "en" ? "guest" : "匿名用户";
    if (username == "匿名用户") {
      $(".mc_name").attr("placeholder", "姓名（选填）");
      return;
    } else {
      $(".mc_name").val(username);
      return;
    }
  }
  $(".mc_name").val(topbar.user.userName);
  return;
};

/**
 * 异步加载local导航条中的城市信息和城市列表信息
 * @type {{nav: common.local.nav}}
 */
common.local = {
  nav: function() {
    if ($(".nav_top").find(".nav-local-li").length) {
      if (common.ishttps) {
        $.ajax({
          url: common.navUrl,
          dataType: "jsonp",
          type: "get",
          jsonp: "callback",
          jsonpCallback: "setdata",
          data: {
            ishttps: 1
          },
          success: function(data) {},
          error: function(XMLHttpRequest, textStatus, errorThrown) {}
        });
      } else {
        $.get(
          common.navUrl,
          {
            ishttps: 0
          },
          function(data) {
            $(".nav_top")
              .find(".nav-local-li")
              .html(data);
          },
          "text"
        );
      }
    }
  },
  setdata: function(data) {
    $(".nav_top")
      .find(".nav-local-li")
      .html(data);
  }
};
function setdata(data) {
  $(".nav_top")
    .find(".nav-local-li")
    .html(data);
}

// 分类页面store用js外调
common.getSeoStores = function(content) {
  $("#hot_deal").html($.base64.atob(content, true));
};

//异步广告位的处理逻辑
function LoadADScript(container, parentId, callback) {
  this.dw = document.write;
  this.container = container;
  this.callback = callback || function() {};
  this.id = parentId;
}

LoadADScript.prototype = {
  startLoad: function() {
    var _this = this;
    var $parent = _this.container.parent();
    //new code
    //解决script src写到一半就已经触发了导致的问题
    //先暂存起来, 等script内容写完,再到timeout去一次性append, 这个想法很赞!
    document.write = document.writeln = (function() {
      var buffer = "";
      var timer;
      return function(htmlPieceToWrite) {
        buffer += htmlPieceToWrite;
        clearTimeout(timer);
        timer = setTimeout(function() {
          $(buffer).appendTo($parent);
          buffer = "";
        }, 0);
      };
    })();

    var val = _this.container.val();
    if (_this.container.data("base64") === 1) {
      val = helper.base64decode(val);
    }

    $parent.append($(val));
  }
};

common.showTextareaAd = function() {
  setTimeout(function() {
    $("textarea.ad-area").each(function(index, node) {
      $(node).removeClass("ad-area");
      new LoadADScript($(node)).startLoad();
    });
  });
};

common.infoLeftfloater = {
  init: function(top, target) {
    var $leftIcon = $(".left_float_icon");
    var $goTop = $('.new_left_back_top[data-role="gotop"]');
    var $comment = $('.left_float_icon [data-role="comment"]');
    var _target = target || ".mcomment.dm_comment_wrapper";
    var $win = $(window);
    var $dealInfoElement = $('[data-role="articleInfo"]:first');
    if ($dealInfoElement.find(".img_wrap").length) {
      $dealInfoElement = $dealInfoElement.find(".img_wrap");
    } else if ($dealInfoElement.find(".collection-content").length) {
      $dealInfoElement = $dealInfoElement.find(".collection-content");
    }
    if ($dealInfoElement.length > 0) {
      var dealInfoElementOffset = $dealInfoElement.offset();
      $leftIcon.css("top", dealInfoElementOffset.top);
      $goTop.css("top", dealInfoElementOffset.top - 40);
      setTimeout(function() {
        $leftIcon.show();
      }, 300);
    }
    var toggleGoTop = function() {
      var scrollTop = $win.scrollTop();
      if (scrollTop > top) {
        $goTop.addClass("show");
      } else {
        $goTop.removeClass("show");
      }
    };
    var scrollTo = function(offset) {
      $("html,body")
        .stop(true, false)
        .animate(
          {
            scrollTop: offset
          },
          500
        );
    };
    $.dmscroll(function() {
      toggleGoTop();
    });
    $goTop.on("click", function() {
      scrollTo(0);
    });
    $comment.on("click", function() {
      var offsetTop = 0;
      var offset = 0;
      if ($(_target).is(":visible")) {
        offset = $(_target).offset().top;
        scrollTo(offset - 10);
      } else {
        offset = $('[data-target="show-comment"]').offset().top;
        scrollTo(offset - 10);
      }
    });
    toggleGoTop();
  },
  updateCommentNum: function(num) {
    var $com = $('.left_float_icon [data-role="comment"]');
    var $comNum = $com.find('[data-role="comnum"]');
    if (typeof num == "number" && num > 0) {
      $comNum.text(num);
      $comNum.show();
    }
  }
};

window.dmLazyCall(function() {
  common.showTextareaAd();
});

//信用卡-Disclosure弹层
common.creditCardLayer = function() {
  var $credit_disclosure = $('.mbx .credit_disclosure');
  var $scroll_top_right  = $('.scroll_top_right');
  if ($credit_disclosure.length && $scroll_top_right) {
    var right = $scroll_top_right.width();
    $credit_disclosure.show().css({marginRight:right+20,marginTop:5,display:'inline-block'}); 
  }else if($credit_disclosure.length){
    $credit_disclosure.show(); 
  }
  $(document)
    .on("click", ".credit_disclosure", function() {
      var creditHtml =
        '<div class="credit_alert_mask"></div>' +
        '<div class="credit_alert_content highlight_font">' +
        '<i class="credit_alert_close"></i>' +
        "<h3>Advertiser Disclosure</h3>" +
        "<p>Advertiser Disclosure: The finance offers that appear on this site are from finance companies from which dealmoon.com receives compensation. This compensation may impact how and where products appear on this site (including, for example, the order in which they appear). Dealmoon.com does not include all finance companies or all available finance offers.</p>" +
        "<p>Editorial Note: Any opinions, analyses, reviews, recommendations and other editorial content contained on this site are those of the author's alone, and have not been reviewed, approved or otherwise endorsed by any finance company.</p>" +
        "</div>";
      $("body").append(creditHtml);
    })
    .on("click", ".credit_alert_close", function() {
      $(".credit_alert_mask, .credit_alert_content").remove();
    });
};

common.creditJump = function() {
  var countryCode = $('[data-role="countrycode"]').val() || "";
  var creditCardLink = $('[data-role="creditcardlink"]').val();
  var domain = document.domain;
  var link = {
    cn: "https://www.dealmoon.com/cn/event/finance",
    en:
      creditCardLink ||
      "https://www" + domain.slice(domain.indexOf(".")) + "/credit-cards"
  };
  var showModal = function() {
    var html = `<div class="credit-jump-select-modal">
            <div class="credit-jump-select-modal-dialog">
                <div class="title">请选择默认跳转页面<span class="close-modal" data-role="closemodal"><i class="dm-icon dm-icon-close"></i></span></div>
                <ul class="selector" data-role="creditselector">
                    <li><a data-val="cn" href="${link.cn}" target="_blank">中国信用卡</a></li>
                    <li><a data-val="en" href="${link.en}" target="_blank">北美信用卡</a></li>
                </ul>
            </div>
            <div class="credit-jump-select-cover"></div>
        </div>`;

    if (!$(".credit-jump-select-modal").length) {
      $(html).appendTo("body");
    }

    $(".credit-jump-select-modal").show();
    $(".credit-jump-select-modal")[0].offsetWidth; // force reflow
    $(".credit-jump-select-modal").addClass("show");
  };
  var hideModal = function() {
    $(".credit-jump-select-modal")
      .removeClass("show")
      .hide();
  };
  var $creditTab = $("#creditcard");

  //信用卡点击事件
  $creditTab.on("click.creditcard", function() {
    if (fromChinaSite) {
      // 中国站直接跳转
      window.open("https://www.dealmoon.cn/event/finance");
    } else if (
      // 如果中国
      countryCode.toUpperCase() == "CN" &&
      !$.cookie("selectedCreditJump")
    ) {
      showModal();
    }
  });

  //选择信用卡跳转事件
  $(document)
    .on("click", '[data-role="creditselector"] li', function() {
      hideModal();
      var val = $(this).data("val") || "cn";
      var selectedCreditJump = $(this)
        .find("a")
        .attr("href");
      $.cookie("selectedCreditJump", selectedCreditJump, {
        expires: 15,
        domain: document.domain.slice(document.domain.indexOf(".")),
        path: "/"
      });
      $creditTab.find("a").attr("href", selectedCreditJump);
      $creditTab.off("click.creditcard");
    })
    .on("click", '[data-role="closemodal"]', function() {
      hideModal();
    })
    .on("click", ".credit-jump-select-cover", function() {
      hideModal();
    });
};

common.dmClipboard = function(ele, cb) {
  var callback = {
    success: function() {},
    error: function() {}
  };
  var option = $.extend({}, callback, cb || {});
  $(ele).each(function(index, thisEle) {
    var clipboard = new Clipboard(thisEle, {
      target: function() {
        return thisEle;
      }
    });
    clipboard.on("success", function(e) {
      typeof option.success == "function" && option.success(e);
      console.log(e);
    });
    clipboard.on("error", function(e) {
      typeof option.error == "function" && option.error(e);
      console.log(e);
    });
  });
};

common.getAdjustCampaign = function () {
  var referrer_url = $.cookie('referrer_url');
  var referrer = /^https?\:\/\/([\w\-\.\_]+\.)?(google|baidu)\.(com|cn|co\.\w+|com\.\w+)(\/|$)/i.test(referrer_url) ? RegExp.$2 : '';
  if (referrer && referrer_url) {
    return 'pc_organic';
  } else if ($.cookie('utm_campaign')) {
    return $.cookie('utm_campaign');
  } else {
    return 'pc_direct';
  }
};

common.headerGenQrCode = function() {
  // 顶部导航生成下载app二维码
  const campaign = common.getAdjustCampaign();
  const adgroup = window.pageInfo['adjustLabel'] ? window.pageInfo['adjustLabel'].replace(/[_\d]*/g, '') : location.pathname.substring(1).replace(/\//g, '-');
  [].forEach.call($(".j_header_download_qrcode"), item => {
    const size = item.closest('.brand-info') ? 80 : 108;
    const codeOptions = {
      text: `${item.getAttribute("data-adjust")}?campaign=${campaign.length ? campaign : 'pc_homecn_header_qrcode'}&adgroup=${adgroup}${window.pageInfo['adjustLabel'] ? '&label=' + window.pageInfo['adjustLabel'] : ''}`,
      correctLevel: 0,
      width: size,
      height: size,
      foreground: "#212121"
    };
    $(item).qrcode(codeOptions);
  });
};

common.rightFloatGenQrCode = function(opts = {}) {
  // 右侧导航生成下载app二维码
  const $qrcodeContainer = opts.$target ? opts.$target : $(".j_right_nav_download_qrcode");

  if ($qrcodeContainer && $qrcodeContainer.length && !$qrcodeContainer.hasClass('qrcode-loaded')) {
    const adgroup = window.pageInfo['adjustLabel'] ? window.pageInfo['adjustLabel'].replace(/[_\d]*/g, '') : location.pathname.substring(1).replace(/\//g, '-');
    const codeOptions = {
      text: `${window.appDownloadAdjustLinkUrl}?campaign=${common.getAdjustCampaign()}&adgroup=${adgroup}${window.pageInfo['adjustLabel'] ? '&label=' + window.pageInfo['adjustLabel'] : ''}`,
      correctLevel: 0,
      width: opts.size || 80,
      height: opts.size || 80,
      foreground: "#da3764"
    };

    if (opts.foreground) {
      codeOptions.foreground = opts.foreground;
    } else {
      delete codeOptions.foreground;
    }
    
    $qrcodeContainer.addClass('qrcode-loaded').qrcode(codeOptions);
  }
};

$(function() {
  //信用卡跳转
  common.creditJump();
  common.headerGenQrCode();
  common.rightFloatGenQrCode();
  //TODO 合集页面中的iframe「折扣」「长文章」直接替换src
  // $('.coll_max_height').find('iframe[data-src]').each(function(index, dom) {
  //     $(dom).attr('src', $(dom).attr('data-src'));
  // });
  //TODO 可以点击后复制微信账号，暂不开放
  // $('[data-type="dm-embed-webchat-account"]').on('click',function(e){
  //     e.preventDefault();
  // })
  // common.dmClipboard('[data-type="dm-embed-webchat-account"]',{
  //     success:function(e){
  //         alert('已复制微信账号'+e.text);
  //     },
  //     error:function(e){
  //         alert('浏览器版本不支持，请尝试手动复制该微信账号！');
  //     }
  // });
  // $('.sp-in-deal .sp-origin-link img').one('error',function(){
  //   $(this).attr('src','/assets/images/guide-modal-user-avtar.png');
  // });
});
var historyAside = document.getElementById('detail-history');
if (historyAside) {
  history.aside(historyAside);
}

require("./common.css");
export default common;
