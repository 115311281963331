/*
* @Author: jiayao.deng
* @Date:   2018-11-28 11:22:16
* @Last Modified by:   jiayao.deng
* @Last Modified time: 2019-04-08 15:44:26
* @Desc: 精选攻略的轮播效果
* @Used site: 页面右侧精选攻略轮播组件
*/
import './index.less';

const gonglueTab = function(obj, speed) {
  var from = 0;
  var $children = $(obj).children('.gonglue_cont');
  var $num1 = $(obj)
    .prev()
    .find('span')
    .eq(0);
  var setTab = function(i) {
    if ($children) {
      var len = $children.length;
      if (i == len) {
        i = 0;
        from = 0;
      }
      var k = i + 1;
      var prev = $children
        .eq(i)
        .prev()
        .find('.gonglue_img_wrap')
        .find('img');
      var next = $children
        .eq(i)
        .next()
        .find('.gonglue_img_wrap')
        .find('img');
      $children
        .hide()
        .eq(i)
        .fadeIn(800);
      $num1.html(k);
      prev.addClass('lazypreload');
      next.addClass('lazypreload');
      if (
        $children
          .eq(i)
          .parent()
          .data('type') == 'cate'
      ) {
        var href = $('.gonglue_more')
          .attr('href')
          .split('=')[0];
        var id = $children.eq(i).data('id');
        $('.gonglue_more').attr('href', href + '=' + id);
      }
    }
  };
  var setTime = setInterval(function() {
    from++;
    setTab(from);
  }, speed);
  $('.box_gonglue .post-button-prev').on('click', function() {
    from--;
    clearTimeout(setTime);
    if (from == -1) {
      from = $children.length - 1;
    }
    setTab(from);
  });
  $('.box_gonglue .post-button-next').on('click', function() {
    from++;
    clearTimeout(setTime);
    if (from == $children.length) {
      from = 0;
    }
    setTab(from);
  });

  $('.box_gonglue')
    .on('mouseenter', function() {
      clearTimeout(setTime);
    })
    .on('mouseleave', function() {
      clearTimeout(setTime);
      setTime = setInterval(function() {
        from++;
        setTab(from);
      }, speed);
    });
};

export default gonglueTab