/**
 * 邮件激活过来，做对应提示
 */

const tipsMap = {
  cn: {
    active: '邮箱已激活成功！',
    update: '邮箱已更换成功！',
    btntext: '知道了',
  },
  en: {
    active: 'Your email has been successfully activated.',
    update: 'Your email has been successfully updated.',
    btntext: 'OK',
  },
}

$(document).ready(function () {
  const emailverify = $.cookie('emailverify')
  $.cookie('emailverify', null, { path: '/' })
  const lan = window.dmLang === 'en' ? 'en' : 'cn'
  if (emailverify) {
    if (emailverify === 'active') {
      if (window.dmWinpop) {
        dmWinpop.alert(tipsMap[lan].active)
      } else {
        alert(tipsMap[lan].active)
      }
    } else if (emailverify === 'update') {
      if (window.dmWinpop) {
        dmWinpop.alert(tipsMap[lan].update)
      } else {
        alert(tipsMap[lan].update)
      }
    }
    $('.J_AltBtn.pop-btn.confirm-true').attr('value', tipsMap[lan].btntext)
  }
})
