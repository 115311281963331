import './index.css';
import './right-ugc.css';
import './credit.less';
import './history.less';

import './emojify';
import './tongji';

import '../widget/rank';

import '../widget/hot';
import '../widget/topic-activity'
import '../widget/left-deal-info-quick-menu';

import gonglueTab from '../widget/gonglue-tab';
import showPost from '../widget/show-post';

$(function () {
  // 精选攻略
  gonglueTab('.gonglue_cont_wrap', 8000)
  index.tongji('.gonglue_cont a', 'pc_view-homecn', 'nav_view_guide')

  // 精选笔记
  showPost('#showPost', 8000)
  index.tongji('.showBox_main a', 'pc_view-homecn', 'nav_view_post')
});

export default window.index;
