//验证邮箱
window.CheckEmail = strEmail => {
    var reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    strEmail = strEmail.replace(/^\s+/g, '');
    strEmail = strEmail.replace(/\s+$/g, '');
    if (!reg.test(strEmail)) return false;
    else return true;
  };
  
  window.CheckName = Name => {
    var reg = /^[\u4E00-\u9FA5a-zA-Z0-9_]{0,30}$/;
    var reg1 = /^([\u4E00-\u9FA5a-zA-Z_])+(.*)$/;
    Name = Name.replace(/^\s+/g, '');
    Name = Name.replace(/\s+$/g, '');
    if (getLen(Name) < 4 || getLen(Name) > 30) {
      {
        if (getLen(Name) < 4) {
          return 'short';
        }
        if (getLen(Name) > 30) {
          return 'long';
        }
      }
    } else if (!reg1.test(Name)) {
      return 'error1';
    } else if (!reg.test(Name)) {
      return 'error2';
    } else {
      return true;
    }
  };
  
  window.CheckPwd = (Pwd, notComplex) => {
    Pwd = Pwd.replace(/^\s+/g, '');
    Pwd = Pwd.replace(/\s+$/g, '');
    if (window.fromChinaSite && !notComplex) {
      var pwdReg =
        /^(?![A-Za-z0-9]+$)(?![a-z0-9\W_]+$)(?![A-Za-z\W_]+$)(?![A-Z0-9\W_]+$)(?!^.*([\u4E00-\u9FA5]|\s).*$)[a-zA-Z0-9\W_]{8,32}$/
      return pwdReg.test(Pwd) ? true : 'easy';
    }
    if (getLen(Pwd) < 6 || getLen(Pwd) > 32) {
      if (getLen(Pwd) < 6) {
        return 'short';
      }
      if (getLen(Pwd) > 32) {
        return 'long';
      }
    } else return true;
  };
  
  function getLen(str) {
    var totallength = 0;
    for (var i = 0; i < str.length; i++) {
      //charCodeAt 返回一个整数，获得字符的unicode编码
      var intCode = str.charCodeAt(i);
      if (intCode >= 0 && intCode <= 128) {
        //为字符的编码范围
        totallength = totallength + 1; //非中文单个字符长度加1
      } else {
        totallength = totallength + 2; //中文字符长度则加2
      }
    }
    return totallength;
  }
  
  window.pwdLevel = value => {
    var pattern_1 = /^.*([\W_])+.*$/i;
    var pattern_2 = /^.*([a-zA-Z])+.*$/i;
    var pattern_3 = /^.*([0-9])+.*$/i;
    var level = 0;
  
    if (value.length < 6) {
      level = 1;
    } else {
      if (pattern_1.test(value)) {
        level++;
      }
      if (pattern_2.test(value)) {
        level++;
      }
      if (pattern_3.test(value)) {
        level++;
      }
      if (level >= 3) {
        level = 3;
      }
    }
  
    return level;
  };
  
  window.pwdstrength = obj => {
    var element = $('#J-pw-strength__bar');
    var value = $(obj).val();
  
    if (value.length >= 1 && /^.*([\W_a-zA-z0-9-])+.*$/i.test(value)) {
      var level = pwdLevel(value);
      switch (level) {
        case 1:
          element.removeClass('pw-strength__bar--normal pw-strength__bar--strong').addClass('pw-strength__bar--weak');
          break;
        case 2:
          element.removeClass('pw-strength__bar--weak pw-strength__bar--strong').addClass('pw-strength__bar--normal');
          break;
        case 3:
          element.removeClass('pw-strength__bar--normal pw-strength__bar--weak').addClass('pw-strength__bar--strong');
          break;
        default:
          break;
      }
    }
  };
  
  /*mail show*/
  var emailSurfixArray = ['@163.com', '@126.com', '@qq.com', '@sina.com', '@gmail.com', '@hotmail.com', '@sohu.com', '@vip.163.com', '@vip.126.com', '@188.com', '@139.com', '@yeah.net'];
  
  function moreName(event) {
    var sval = this.value;
    event = event ? event : window.event;
    var keyCode = event.keyCode;
    var vschool = $('#intelligent-regName');
    if (keyCode == 40 || keyCode == 38 || keyCode == 13) {
      var tipindex = $('#hnseli').val() == '' ? -1 : $('#hnseli').val();
      var fobj;
      if (keyCode == 40) {
        tipindex++;
        if (tipindex == vschool.find('li').length) {
          tipindex = 0;
          vschool
            .find('li')
            .eq(vschool.find('li').length - 1)
            .css('background-color', '');
        }
        fobj = vschool.find('li').eq(tipindex);
        vschool
          .find('li')
          .eq(tipindex - 1)
          .css('background-color', '');
        fobj.css('background-color', '#eee');
        $('#regMail').val(fobj.html().replace(/<(\S*?)[^>]*>|<.*? \/>/g, ''));
        $('#schoolid').val(fobj.attr('value'));
        $('#hnseli').val(tipindex);
        return;
      } else if (keyCode == 38) {
        tipindex--;
        if (tipindex <= -1) {
          tipindex = vschool.find('li').length - 1;
          vschool
            .find('li')
            .eq(0)
            .css('background-color', '');
        }
        vschool
          .find('li')
          .eq(tipindex + 1)
          .css('background-color', '');
        fobj = vschool.find('li').eq(tipindex);
        fobj.css('background-color', '#eee');
        if (fobj.html() != null) {
          $('#regMail').val(fobj.html().replace(/<(\S*?)[^>]*>|<.*? \/>/g, ''));
          $('#schoolid').val(fobj.attr('value'));
        }
        $('#hnseli').val(tipindex);
        return;
      } else if (keyCode == 13) {
        $('#hnseli').val('-1');
        if ($('#regMail').val().length >= 1) {
          var combinedValue = vschool
            .find('li')
            .eq(tipindex)
            .html();
          //alert(combinedValue)
          if (combinedValue != null) {
            $('#regMail').val(combinedValue.replace(/<(\S*?)[^>]*>|<.*? \/>/g, ''));
          }
          vschool.empty().hide();
          if ($('#schoolid').val() != '') {
            $('#hnschool').val('1');
            $('#hnschool').attr('sta', 2);
            $('#regMail').blur();
          } else {
            $('#hnschool').val('-1');
            $('#hnschool').attr('sta', 0);
            // $("#regNamel_error").html("");
            //$("#regName_succeed").removeClass("succeed");
          }
  
          return;
        }
      }
    } else {
      //hide morePin
  
      if (sval != '') {
        var userinput = sval;
        var oldSval = '';
        var pos = sval.indexOf('@');
        if (pos >= 0) {
          oldSval = sval.substring(0, pos);
        }
  
        $('#schoolid').val('');
        $('#hnseli').val('-1');
        var html = '';
        if (/[\u4E00-\u9FA5]/g.test(sval)) {
          html = '<li>' + sval + '</li>';
        } else {
          if (oldSval != '') {
            sval = oldSval;
          }
          if (userinput.indexOf('@') == 0) {
            sval = '';
          }
          html = '<li>' + userinput + '</li>';
          var partSurfix = initEmailSurfixArray(userinput);
          if (partSurfix != null) {
            for (var i = 0; i < partSurfix.length; i++) {
              html += '<li>' + sval + partSurfix[i] + '</li>';
            }
          }
        }
        if (sval.length > 25) {
          $('#intelligent-regName').hide();
        } else {
          $('#intelligent-regName').show();
          $('#intelligent-regName')
            .html(html)
            .find('li')
            .on('mousedown', function() {
              $('#regMail').val($(this).html());
              $('#schoolid').val($(this).attr('value'));
              $('#hnseli').val('-1');
            });
          $('#regMail').on('blur', function() {
            $('#intelligent-regName').hide();
          });
        }
      } else {
        $('#intelligent-regName')
          .html('')
          .hide();
        $('#schoolid').val('');
        $('#hnseli').val('-1');
      }
    }
  }
  
  $(function() {
    $('#regMail').on('keyup', moreName);
    $('#regMail').on('focus', moreName);
  });
  
  function initEmailSurfixArray(str) {
    var pos = str.indexOf('@');
    if (pos < 0 || pos == str.length - 1) {
      return emailSurfixArray;
    }
    var inputSurfix = str.substring(pos, str.length);
    var suitableSurfixArray = [];
    var j = 0;
    for (var i = 0; i < emailSurfixArray.length; i++) {
      if (emailSurfixArray[i].indexOf(inputSurfix) == 0) {
        suitableSurfixArray[j] = emailSurfixArray[i];
        j++;
      }
    }
  
    return suitableSurfixArray;
  }
  
  $('#intelligent-regName li')
    .on('mouseover', function() {
      var vi = $(this).attr('dex');
      $('#intelligent-regName li').each(function() {
        $(this)
          .css('background-color', '')
          .attr('class', '');
      });
      $(this)
        .css('background-color', '#eee')
        .attr('class', 'xuan');
    })
    .on('mouseleave', function() {
      $(this).css('background-color', '');
    })
    .on('click', function() {
      $(this)
        .parent()
        .css('display', 'none');
    });
  
  window.changeCode = () => {
    //刷新验证码
    $('#ivcode').attr('src', '/captcha/vcode?r=' + Math.random());
  };
  
  $('#logMail')
    .on('focus', function() {
      $(this).addClass('text focusborder');
    })
    .on('blur', function() {
      $(this).removeClass('focusborder');
    });
  $('#hidPass').on('focus', function() {
    $('#logPass').addClass('text focusborder');
  });
  $('#logPass')
    .on('focus', function() {
      $(this).addClass('text focusborder');
    })
    .on('blur', function() {
      $(this).removeClass('focusborder');
    });
  
  window.openlogin = lang => {
    $('.icon-pic-reg').off('click');
    location.href = '/login?language=' + lang;
  };
  
  window.openregist = lang => {
    location.href = '/register?language=' + lang;
  };