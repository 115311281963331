require('../../_common/common');
require('../../_common/index/index');

require('../reset.less');
require('../register/register.less');

require('../register/common');
require('./emailTip');
import { hex_md5 } from '../md5';
import { googlePopUp } from '../login/google-login';

window.hex_md5 = hex_md5;

var callbackurl = new Array();
callbackurl['1'] = location.origin + '/sendemail?action=active&code=';

window.resendMail = (email, action, urlKey) => {
  $('#load').show();
  $('#resendstate').hide();
  if ($('#load').attr('status') == 'send') {
    return false;
  }
  $('#load').attr('status', 'send');
  if (!email) {
    return false;
  }
  if (urlKey > 0) {
    $.get('/sendemail/ajax_encrypt_email', { email: email }, function(code) {
      top.location.href = callbackurl[urlKey] + code;
    });
  } else {
    $.get(
      '/sendemail',
      {
        email: email,
        action: action,
        type: 'ajax'
      },
      msg => {
        $('#load').hide().attr('status', '');
        if (msg.state) {
          $('#resendstate').show();
        } else {
          alert(msg.msg)
        }
      },
      'json'
    );
  }
};

$(document).ready(function () {
  // 如果有错误信息过来，展示错误信息
  const errTip = $.cookie('error_tip')
  if (errTip) {
    dmWinpop.alert(errTip)
    $.cookie('error_tip', null, { path: '/' })
  }
  $('.other_zh .google').on('click',function(){
    googlePopUp('loginPage',googleOAuth2Url);
  });
})