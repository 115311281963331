/*
* @Author: jiayao.deng
* @Date:   2018-12-07 10:32:59
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2023-04-26 21:03:10
*/
import './left-deal-info-quick-menu.less';

const infoLeftfloater = {
  init: function(top, target) {
    var $leftIcon = $('.left_float_icon');
    var $goTop = $('.new_left_back_top[data-role="gotop"]');
    var $buy = $leftIcon.find('.link_icon_buy');
    var buyButton = document.querySelector('.page-type-dealdetail .left-cnt .btn-buy') || document.querySelector('.page-type-baoliaodetail .left-cnt .btn-buy');
    var $comment = $('.left_float_icon [data-role="comment"]');
    var _target = target || '.mcomment.dm_comment_wrapper';
    var $win = $(window);
    var $dealInfoElement = $('[data-role="articleInfo"]:first');
    var leftFixTop = 0;
    if ($dealInfoElement.find('.img_wrap').length) {
      $dealInfoElement = $dealInfoElement.find('.img_wrap');
    } else if ($dealInfoElement.find('.collection-content').length) {
      $dealInfoElement = $dealInfoElement.find('.collection-content');
    }
    if ($dealInfoElement.length > 0) {
      var dealInfoElementOffset = $dealInfoElement.offset();
      leftFixTop = dealInfoElementOffset.top;
      $leftIcon.css('top', leftFixTop);
      $goTop.css('top', leftFixTop - 40);
      setTimeout(function() {
        $leftIcon.show();
      }, 300);
    }
    var toggleGoTop = function() {
      var scrollTop = $win.scrollTop();
      if ($buy.length && buyButton) {
        var isBuyButtonInvisible = false;
        var bounds = buyButton.getBoundingClientRect();
        if (bounds && (bounds.bottom <= 0 || bounds.top >= document.documentElement.clientHeight)) {
          isBuyButtonInvisible = true;
        }
        if (isBuyButtonInvisible) {
          $buy.addClass('active');
        } else {
          $buy.removeClass('active');
        }
      }
      if (scrollTop > top) {
        $goTop.addClass('show');
      } else {
        $goTop.removeClass('show');
      }
    };
    if (!buyButton && $buy[0]) {
      $buy.addClass('active');
    }
    var scrollTo = function(offset) {
      $('html,body')
        .stop(true, false)
        .animate(
          {
            scrollTop: offset
          },
          500
        );
    };
    $.dmscroll(function() {
      toggleGoTop();
    });
    $goTop.on('click', function() {
      scrollTo(0);
    });
    // $(document).on('event-loaded', () => {
    //   if ($dealInfoElement.length > 0) {
    //     leftFixTop += 32;
    //     setTimeout(() => {
    //       $leftIcon.animate({'top': leftFixTop}, 500);
    //     }, 20);
    //     $goTop.css('top', leftFixTop - 40);
    //   }
    // })
    
    if ($dealInfoElement.length > 0) {
      leftFixTop += 32;
      setTimeout(() => {
        $leftIcon.animate({'top': leftFixTop}, 500);
      }, 20);
      $goTop.css('top', leftFixTop - 40);
    }
    $(document).on('event-hided', () => {
      if ($dealInfoElement.length > 0) {
        leftFixTop -= 32;
        setTimeout(() => {
          $leftIcon.animate({'top': leftFixTop}, 500);
        }, 20);
        $goTop.css('top', leftFixTop - 40);
      }
    });
    $comment.on('click', function() {
      var offset = 0;
      if ($(_target).is(':visible')) {
        offset = $(_target).offset().top;
        scrollTo(offset - 10);
      } else {
        offset = $('[data-target="show-comment"]').offset().top;
        scrollTo(offset - 10);
      }
    });
    toggleGoTop();
  },
  updateCommentNum: function(num) {
    var $com = $('.left_float_icon [data-role="comment"]');
    var $comNum = $com.find('[data-role="comnum"]');
    if (typeof num == 'number' && num > 0) {
      $comNum.text(num);
      $comNum.show();
    }
  }
};

window.widget = window.widget || {};
window.widget.infoLeftfloater = infoLeftfloater;
