/*
* @Author: jiayao.deng
* @Date:   2018-11-28 15:10:02
* @Last Modified by:   jiayao.deng
* @Last Modified time: 2019-04-08 16:04:38
*/
import './rank.less';

function rankAction() {
  //1. a标签点击后return false(只propogation, 不处理)
  $('.click_rank .b_list a').on('click', function(e) {
    $(this)
      .closest('div.b_list')
      .click();
    return false;
  });
  //2. a标签mouseover后恢复href链接
  $('.click_rank .b_list a').one('mouseover', function() {
    var text = $(this)
      .closest('div.b_list')
      .attr('onclick');
    var href = text.match(/javascript:window\.open\('(.+)'\)/)[1];
    $(this).attr('href', href);
  });
}

window.widget = window.widget || {};
window.widget.rankAction = rankAction;
