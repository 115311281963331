/*
* @Author: jiayao.deng
* @Date:   2018-11-28 14:41:53
* @Last Modified by:   jiayao.deng
* @Last Modified time: 2018-11-28 15:00:38
*/

window.index = window.index || {};

index.tongji = function(obj, category, action) {
  $(obj).on('click', function() {
    window.ga4 && ga4('send', {
      hitType: 'event',
      eventCategory: category,
      eventAction: action,
      eventLabel: ' ',
      eventValue: 1
    });
  });
};
