/*
* @Author: jiayao.deng
* @Date:   2018-11-28 15:24:07
* @Last Modified by:   jiayao.deng
* @Last Modified time: 2018-11-28 15:24:41
*/

window.index = window.index || {};

index.emojify = function(obj) {
  $(obj)
    .not('.emojified')
    .emojify({ fontSize: 14 })
    .addClass('emojified');
};
