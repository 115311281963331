
const HISTORY_VIEW_PAGESIZE = 10;
const HISTORY_MAX_PAGE = 5;
const HISTORY_MAX = HISTORY_MAX_PAGE * HISTORY_VIEW_PAGESIZE;
const HISTORY_KEY = 'dm-detail-viewed-history';
const HISTORY_REPLOAD_PAGE = 2;
const MAX_FIXED_RANK_COUNT = 6;

export const clearHistory = () => {
  try {
    localStorage.removeItem(HISTORY_KEY);
  } catch (err) {
    console.error(err);
  }
};

const validate = (detail) => {
  return detail.resType && detail.resId > 0;
};

export const addHistory = (detail) => {
  let history;
  let valid = validate(detail);
  if (!valid) {
    return;
  }
  try {
    history = JSON.parse(localStorage.getItem(HISTORY_KEY));
  } catch (err) {
    console.error(err);
  }
  if (!Array.isArray(history)) {
    history = [];
  }
  let length = history.length;
  for (let l = length - 1; l >= 0; l--) {
    if (history[l] && history[l].resId == detail.resId && history[l].resType == detail.resType) {
      history.splice(l, 1);
    }
  }
  if (length > HISTORY_MAX) {
    history = history.splice(0, HISTORY_MAX);
  }
  try {
    localStorage.setItem(HISTORY_KEY, JSON.stringify([{
      ...detail,
      url: detail.url ? detail.url : location.href.split('#')[0].split('?')[0],
      viewedTime: Date.now()
    }, ...history]));
  } catch (err) {
    console.error(err);
  }
  return history;
};

function isFixedList (container) {
  return container.parentNode.id === 'scroll-fix' && window.common && window.common.right && typeof window.common.right.adFloat === 'function';
}

function scrollFixRank (container) {
  return;
  if (!isFixedList(container)) {
    return;
  }
  let fixedHistory = container.parentNode;
  let aside = fixedHistory.parentNode;
  fixedHistory.innerHTML = '';
  let rank = aside.querySelector('#rankList.click_rank');
  if (rank) {
    let cloneRank = rank.cloneNode(true);
    cloneRank.id = 'fixed-rank-list';
    let content = cloneRank.querySelector('.content');
    let items = content.children;
    let count = items.length;
    if (count > MAX_FIXED_RANK_COUNT) {
      for (let i = count - 1; i >= MAX_FIXED_RANK_COUNT; i --) {
        content.removeChild(items[i]);
      }
    }
    fixedHistory.appendChild(cloneRank);
    window.common.right.adFloat();
  }
}

const view = {
  deal (item, value) {
    let fav = '';
    if (window.fromChinaSite == 1) {
      fav = `<span class="fav"><em class="dm-icon dm-icon-shoucang3"></em>${item.favNums < 10000 ? item.favNums : 9999}</span>`;
    } else {
      fav = `<span class="fav"><em class="dm-icon dm-icon-pinglun1"></em>${item.commentNum < 10000 ? item.commentNum : 9999}</span>`;
    }
    return `<a class="b_list"
    data-dmt 
    data-id="${item.resId}" 
    data-type="deal"
    data-dmt-d-type="deal"
    data-dmt-d-value="${value}"
    data-res-id="${item.resId}" 
    data-dmt-d-deal-id="${item.resId}"
    statrk="click-${item.resId}-deal-text"
    dm-force
    href="${item.url}"
    target="_blank">
    <p class="pic">
      <img src="${item.imgUrl}" alt="" />
    </p>
    <div class="txt">
      <div class="txt_k">
        <p class="txt_k_name">${item.title}</p>
        <p class="txt_k_price">
          ${item.titleEx ? item.titleEx : (item.price ? (item.price + '&nbsp;<b>' + (item.listPrice || '') + '</b>') : '')}
        </p>
      </div>
      <p class="webadd">
        ${fav}
        <span>${item.store}</span>
      </p>
    </div>
</a>`;
  },
  sp (item, value) {
    let url = item.url;
    if (window.dmLang == 'en') {
      url = url.replace('/product/', '/en/product/');
      url = url.replace('/en/en/', '/en/');
      url = url.replace('/en/en/', '/en/');
    } else {
      url = url.replace('/en/product/', '/product/');
      url = url.replace('/en/product/', '/product/');
    }
    let html = `<a trkrip="spDetail-viewedSps" target="_blank" href="${url}" class="b_list"
    data-dmt data-dmt-d-dp-id="${item.resId}"
    data-dmt-d-value="${value}" data-dmt-d-category="${item.category || ''}"
    data-dmt-d-type="sp" data-dmt-d-store-name="${item.store || ''}">
    <div class="pic"><img src="${item.imgUrl}" alt="" /></div>
    <div class="txt">
      <div class="txt_k">
        <p class="txt_k_name">${item.brand ? (item.brand + ' ') : ''}${item.title}</p>
        ${item.discount ? ('<p class="txt_k_price">' + item.discount + '</p>') : ''}`;
    if (item.discountPrice && item.discountPrice.price && item.discountPrice.price != 'null') {
      html += `<p class="txt_k_price">${item.discountPrice.currency}${item.discountPrice.price}${item.originalPrice && item.originalPrice.price != 'null' ? ('&nbsp;<b>' + item.originalPrice.currency + item.originalPrice.price + '</b>') : ''}`;
    } else if (item.originalPrice && item.originalPrice.price && item.originalPrice.price != 'null') {
      html += `<p class="txt_k_price">${item.originalPrice.currency}${item.originalPrice.price}</p>`;
    }
    html += '</div>';
    if (item.viewNum > 0) {
      html += `<p class="webadd"><span>${item.viewNum}人感兴趣</span></p>`;
    } else {
      html += `<p class="webadd"><span>${item.store}</span></p>`;
    }
    html += `</div></a>`;
    return html;
  },
  guide (item, value) {
    return `<a href="/guide/${item.resId}" target="_blank" class="b_list ugc-b-list"
      data-dmt data-dmt-d-guide-id="${item.resId}"
      data-dmt-d-type="guide" data-dmt-d-value="${value}"
    >
    <p class="pic">
      <img src="${item.imgUrl}" alt="" />
    </p>
    <div class="txt">
      <div class="txt_k">
        <p class="txt_k_name">${item.title}</p>
      </div>
      <div class="webadd">
        <p class="ugc-webadd">
          <span class="author">
            <img class="avatar" src="${item.author.avatar}" alt="" />
            <span class="name">${item.author.name}</span>
            <em style="display:none"><i class="dm-icon dm-icon-level21"></i>${item.author.vip ? 'VIP' : ('<b class="level-num">' + item.author.level + '</b>')}</em>
          </span>
          ${item.commentNum > 0 ? '<span class="statistic"><i class="dm-icon dm-icon-pinglun1"></i>' + item.commentNum + '</span>' : ''}
          
        </p>
      </div>
    </div>
    </a>
    `;
  },
  post (item, value) {
    return `<a href="/post/${item.resId}" target="_blank" class="b_list ugc-b-list"
      data-dmt data-dmt-d-post-id="${item.resId}"
      data-dmt-d-type="post" data-dmt-d-value="${value}"
    >
    <p class="pic">
      <img src="${item.imgUrl}" alt="" />
    </p>
    <div class="txt">
      <div class="txt_k">
        <p class="txt_k_name3">${item.title}</p>
      </div>
      <div class="webadd">
        <p class="ugc-webadd">
          <span class="author">
            <img class="avatar" src="${item.author.avatar}" alt="" />
            <span class="name">${item.author.name}</span>
            
          </span>
          ${item.likeNum > 0 ? '<span class="statistic"><i class="dm-icon dm-icon-xihuan1"></i>' + item.likeNum + '</span>' : ''}
          </p>
      </div>
    </div>
    </a>
    `;
  },
  loading: false,
  loadHistoryDetails (data, index) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      $.ajax({
        url: '/www/home/ajax-history',
        type: 'post',
        dataType: 'json',
        data: {
          res: data
        },
        success: (res) => {
          this.loading = false;
          if (res && Array.isArray(res.data) && res.data.length > 0) {
            let history = res.data;
            let htmls = [];
            history.forEach((item) => {
              if (item.resType && typeof view[item.resType] === 'function') {
                let itemHTML = view[item.resType](item, index);
                index++;
                if (itemHTML) {
                  htmls.push(itemHTML);
                }
              }
            });
            resolve(htmls);
          } else {
            resolve([]);
          }
        },
        error: () => {
          this.loading = false;
          reject('error');
        }
      });
    });
  },
  aside (container) {
    let history;
    try {
      history = JSON.parse(localStorage.getItem(HISTORY_KEY));
    } catch (err) {
      console.error(err);
    }
    if (!Array.isArray(history) || history.length === 0) {
      scrollFixRank(container);
      return;
    }
    var pageData = [];
    var pagedHistory = [pageData];
    history.forEach((item) => {
      let size = pagedHistory.length == 1 ? HISTORY_REPLOAD_PAGE * HISTORY_VIEW_PAGESIZE : HISTORY_VIEW_PAGESIZE;
      if (pageData.length >= size) {
        pageData = [];
        pagedHistory.push(pageData);
      }
      pageData.push({
        resId: item.resId,
        resType: item.resType
      });
    });
    let index = 1;
    let page1 = pagedHistory.shift();
    this.loadHistoryDetails(page1, index, true).then((htmls) => {
      let count = htmls.length;
      index += count;
      if (count > 0) {
        let totalCount = history.length - page1.length + count;
        let totalPage = Math.min(Math.ceil(totalCount / HISTORY_VIEW_PAGESIZE), HISTORY_MAX_PAGE);
        let listHTML = [];
        for (let i = 0; i < HISTORY_MAX_PAGE; i++) {
          listHTML.push(htmls.splice(0, HISTORY_VIEW_PAGESIZE) || []);
        }
        let panel = document.createElement('div');
        panel.className = 'detail-history';
        panel.setAttribute('data-dmt-g-c', 'dm-viewed-click');
        panel.setAttribute('data-dmt-d-domain-id', 'user');
        panel.innerHTML = `
        <div class="sub-header">
          <div class="detail-history-title"><h3><i class="dm-icon-zuji"></i></i>${window.dmLang == 'en' ? 'Rencently seen' : '我看过的'}</h3></div>
          <div class="tab-page">
            <span class="clear" role="button" data-dmt data-dmt-g-a="click-dm-right-viewed-clear">清空</span>
            <span class="tab-page-buttons">
              <span class="previous disabled dm-icon dm-icon-zuojiantou_cu disabled"
                data-dmt data-dmt-g-a="click-dm-right-viewed-pageturn"></span>
              <span class="page">
                <span class="current">1</span><span class="total">/${totalPage}</span>
              </span>
              <span class="next dm-icon dm-icon-youjiantou_cu disabled"
                data-dmt data-dmt-g-a="click-dm-right-viewed-pageturn"></span>
            </span>
          </div>
        </div>
        <div class="tab-view">
          <div class="tab-viewport">
            <div class="tab-box-list" data-dmt-g-a="click-dm-right-viewed-feed-content">
              ${listHTML.map((code, p) => {
                if (code && code.length) {
                  return '<div class="tab-box tab-box-' + (p + 1) + '">' + code.join('') + '</div>';
                } else {
                  return '<div class="tab-box tab-box-loading tab-box-' + (p + 1) + '"><div class="mlist-loading">\
                  <div class="spin-group">\
                    <div class="spin">\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                      <div></div>\
                    </div>加载中...\
                  </div>\
                </div></div>';
                }
              }).join('')}
            </div>
          </div>
        </div>
        `;
        let page = panel.querySelector('.tab-page .tab-page-buttons');
        let total = panel.querySelector('.tab-page .total');
        if (total) {
          total.innerHTML = '/' + totalPage;
        }
        page.style.display = totalPage > 1 ? '' : 'none';
        let clear = panel.querySelector('.tab-page .clear');
        let previous = panel.querySelector('.tab-page .previous');
        let next = panel.querySelector('.tab-page .next');
        let current = panel.querySelector('.tab-page .current');
        let viewedList = panel.querySelector('.tab-box-list');
        if (totalPage > 1) {
          next.classList.remove('disabled');
        }
        let currentPage = 1;
        function setTabPage (page) {
          let translate = 'translate(' + ((1 - page) * 10) + '%, 0)';
          current.innerHTML = page;
          viewedList.style.transform = translate;
          viewedList.style.webkitTransform = translate;
          viewedList.style.msTransform = translate;

          if (page > 1) {
            previous.classList.remove('disabled');
          } else {
            currentPage = 1;
            previous.classList.add('disabled');
          }
          if (page < totalPage) {
            next.classList.remove('disabled');
          } else {
            currentPage = totalPage;
            next.classList.add('disabled');
          }
        }
        previous.addEventListener('click', function () {
          currentPage = currentPage - 1;
          if (totalPage > 1 && currentPage < totalPage) {
            next.classList.remove('disabled');
          }
          setTabPage(currentPage);
        });
        next.addEventListener('click', () => {
          if (currentPage >= totalPage) {
            currentPage = totalPage;
            total.innerHTML = '/' + totalPage;
            next.classList.add('disabled');
          }
          currentPage = currentPage + 1;
          if (currentPage >= totalPage) {
            currentPage = totalPage;
            next.classList.add('disabled');
          } else if (totalPage > 1) {
            next.classList.remove('disabled');
          }

          if (pagedHistory.length > 0 && !this.loading) {
            let req = pagedHistory.shift();
            this.loadHistoryDetails(req, index, false).then((htmls) => {
              count = htmls.length;
              index += count;
              if (req.length != count) {
                totalCount = totalCount - req.length + count;
                totalPage = totalPage = Math.min(Math.ceil(totalCount / HISTORY_VIEW_PAGESIZE), HISTORY_MAX_PAGE);
                total.innerHTML = '/' + totalPage;
                if (currentPage >= totalPage) {
                  currentPage = totalPage;
                  next.classList.add('disabled');
                } else if (totalPage > 1) {
                  next.classList.remove('disabled');
                }
              }
              if (count > 0) {
                [].every.call(viewedList.querySelectorAll('.tab-box'), (list, pageindex) => {
                  let listIsLoading = list.querySelector('.mlist-loading');
                  let remain = listIsLoading ? HISTORY_VIEW_PAGESIZE : (HISTORY_VIEW_PAGESIZE - list.childElementCount);
                  if (remain > 0) {
                    let append = htmls.splice(0, remain);
                    if (append.length > 0) {
                      if (list.querySelector('.mlist-loading')) {
                        list.innerHTML = append.join('');
                      } else {
                        list.innerHTML += append.join('');
                      }
                    }
                    if (htmls.length <= 0) {
                      return false;
                    }
                  }
                  return true;
                });
              }
            }).catch(() => {
              pagedHistory.unshift(req);
            });
          }

          setTabPage(currentPage);
        });
        clear.addEventListener('click', function () {
          if (window.dmWinpop) {
            var confirmMessage = window.dmLang === 'en' ? 'Clear the history I\'ve seen?' : '清空我看过的内容列表吗？';
            var confirmClear = function () {
              clearHistory();
              panel.style.display = 'none';
              window.gastatistics.action.ga_event_statistics({
                eventCategory: 'dm-viewed-click',
                eventAction: 'click-dm-right-viewed-clear-confirm',
                DomainID: 'user',
                BusinessUnit: 'dm',
                Language: window.dmLang,
              });
            };
            window.dmWinpop.confirm(
              confirmMessage,
              confirmClear,
              function () { },
              {
                titleStr: '', // window.dmLang == 'en' ? 'Confirm' : '确认',
                okText: window.dmLang == 'en' ? 'Clear' : '清空',
                cancelText: window.dmLang == 'en' ? 'Cancel' : '取消',
              }
            );
          } else if (window.confirm(confirmMessage)) {
            confirmClear();
          }
        });
        container.appendChild(panel);
        if (isFixedList(container)) {
          window.common.right.adFloat();
        }
      }
    });
  }
};
export const history = view;
