/*
* @Author: jiayao.deng
* @Date:   2018-11-28 11:23:53
* @Last Modified by:   jiayao.deng
* @Last Modified time: 2019-04-08 16:47:40
* @Desc: 精选笔记每八秒切换一个
* @Used site: 页面右侧精选笔记组件
*/
import './show-post.less';

const showPost = function (obj, speed) {
  var from = 0;
  var $children = $(obj).children('.showBox');
  var setTab = function(i) {
    if ($children) {
      var len = $children.length;
      if (i == len) {
        i = 0;
        from = 0;
      }
      $children
        .hide()
        .eq(i)
        .fadeIn(1000);
      var prev = $children
        .eq(i)
        .prev()
        .find('.showBox_img')
        .find('img');
      var next = $children
        .eq(i)
        .next()
        .find('.showBox_img')
        .find('img');
      prev.addClass('lazypreload');
      next.addClass('lazypreload');
    }
  };
  var setTime = setInterval(function() {
    from++;
    setTab(from);
  }, speed);
  $('#showPost .post-button-prev').on('click', function() {
    from--;
    clearTimeout(setTime);
    if (from == -1) {
      from = $children.length - 1;
    }
    setTab(from);
  });
  $('#showPost .post-button-next').on('click', function() {
    from++;
    clearTimeout(setTime);
    if (from == $children.length) {
      from = 0;
    }
    setTab(from);
  });

  $(obj)
    .on('mouseenter', function() {
      clearTimeout(setTime);
    })
    .on('mouseleave', function() {
      clearTimeout(setTime);
      setTime = setInterval(function() {
        from++;
        setTab(from);
      }, speed);
    });
}

export default showPost